@tailwind components;
@tailwind utilities;

.bosch-icon {
  padding: 10px;
  color: #000000;
  font-family: 'bosch-icon';
  font-weight: 400;
}

.bosch-icon-np {
  color: #000000;
  font-family: 'bosch-icon';
  font-weight: 400;
}

.result-icon {
  margin-right: 5px;
  margin-top: -5px;
  font-family: 'bosch-icon';
  font-size: 16px;
}

.result-icon {
  margin-right: 5px;
  margin-top: -5px;
  font-family: 'bosch-icon';
  font-size: 16px;
}

.close-icon {
  font-family: 'bosch-icon';
  font-weight: 600;
  font-size: '36px';
  height: '36px';
  display: 'flex';
  align-items: 'center';
  color: #000;
  position: 'absolute';
  top: 10px;
  right: 10px;
  cursor: 'pointer';
}

.close-icon-image {
  font-family: 'bosch-icon';
  font-weight: 600;
  font-size: '36px';
  display: 'flex';
  align-items: 'center';
  color: #005691;
  top: 10px;
  right: '10px';
  cursor: 'pointer';
}

.save-document {
  width: 95px;
  height: 48px;
  color: #ffffff;
  transition: none;
  border-radius: 0px;
  margin-right: -20px;
  background-color: #005691 !important;
  text-transform: none;
}

.save-document:hover {
  background-color: #7fc6e7 !important;
}

.support-icon-image {
  font-family: 'bosch-icon';
  color: #fff;
}

.ac-bosch-icon {
  padding-left: 0;
  padding-right: 10px;
  font-family: 'bosch-icon';
  font-weight: 400;
  color: #666666;
}

.share-article-poppover::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 1em solid black;
  border-top-color: black;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: black;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.15);
  top: 7vh;
  transform: rotate(45deg);
  left: 1px;
}

.search-slide-enter {
  left: 514px;
  position: relative;
}

.search-slide-enter-active {
  transform: translateX(-514px);
  transition: 500ms;
}

.search-slide-exit-active {
  transform: translateX(800px) translateY(0px);
  transition: 500ms;
}

.search-slide-ticket-enter {
  left: 296px;
  position: relative;
}

.search-slide-ticket-enter-active {
  transform: translateX(-296px);
  transition: 500ms;
}

.search-slide-ticket-exit-active {
  transform: translateX(300px) translateY(0px);
  transition: 500ms;
}

.save-search-button {
  height: 50px;
  font-family: 'BoschSans-Light', 'BoschSans-Regular' !important;
  font-size: 14px !important;
  color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 0px;
  margin-left: 10px;
  text-transform: none;
  display: flex;
  align-items: center;
  line-height: 20px;
  text-align: left;
  width: 160px !important;
}

.save-search-button:hover {
  background-color: #3ea4d4;
}

.saved-search-button {
  height: 50px;
  background-color: #3ea4d4 !important;
  margin-top: 0px;
  margin-left: 10px;
  font-family: 'BoschSans-Light', 'BoschSans-Regular' !important;
  font-size: 14px !important;
  color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  text-transform: none;
  display: flex;
  align-items: center;
  line-height: 20px;
  text-align: left;
  width: 180px !important;
}

.saved-search-button:hover {
  background-color: #3ea4d4;
}

.loading-search-button {
  height: 50px;
  width: 160px !important;
  background-color: #3ea4d4 !important;
  margin-top: 0px;
  margin-left: 10px;
  font-family: 'BoschSans-Light', 'BoschSans-Regular' !important;
  font-size: 14px !important;
  color: #ffffff;
  border-radius: 2px;
  cursor: progress;
  text-transform: none;
}

.animation-scroll-btn {
  bottom: 20px;
  transition:
    width 0.5s,
    height 0.5s,
    opacity 0.5s 0.5s,
    bottom 0.5s !important;
}

.animation-scroll-btn.fadeIn-up {
  bottom: 100px !important;
  transition:
    width 0.5s,
    height 0.5s,
    opacity 0.5s 0.5s,
    bottom 0.5s !important;
}

.container-input {
  height: auto !important;
  min-height: 57px !important;
}

.contact-banner {
  transition:
    all 0.5s ease,
    border-radius 0.5s ease,
    opacity 0.5s ease;
  transform-origin: right center;
  overflow: hidden;
}

.contact-banner.circle {
  width: 100px;
  opacity: 0;
  border-radius: 50px;
  margin-left: 100%;
}

.contact-banner.rectangle {
  width: 100%;
  opacity: 1;
  border-radius: 0px;
}

.gradient-text {
  background: linear-gradient(to right, #0ea5e9, #0c4a6e);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}