@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BoschSans";
  src: local('BoschSans-Regular'),
    url('./fonts/BoschSans-Regular.woff2') format("woff2"),
    url('./fonts/BoschSans-Regular.woff') format("woff");
}

@font-face {
  font-family: "BoschSans-Light";
  src: local('BoschSans-Light'),
    url('./fonts/BoschSans-Light.woff') format("woff2"),
    url('./fonts/BoschSans-Light.woff') format("woff");
}

@font-face {
  font-family: "BoschSans-Bold";
  src: local('BoschSans-Bold'),
    url('./fonts/BoschSans-Bold.woff') format("woff2"),
    url('./fonts/BoschSans-Bold.woff') format("woff");
}

@font-face {
  font-family: 'BoschSans-MonoSemiCondensed-Print';
  src: url('./fonts/BoschSansMonoSemiCondensed-Print-v1_002.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #1565c0;
}

a:hover {
  text-decoration: underline;
}

/*Ticket Case*/


.err-icon {
  font-size: 30px;
  font-weight: 400;
  color: red;
  display: inline-flex;
  vertical-align: bottom;
  line-height: 2 !important;
  margin-left: 5%;
}

.warn-icon {
  font-size: 30px;
  font-weight: 400;
  color: #e9ed00;
  display: inline-flex;
  vertical-align: bottom;
  line-height: 2 !important;
  margin-left: 5%;
}

.ticket-form-invalid {
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
}

.info-modal-background {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  flex-grow: 1;
  flex-basis: 0;
  position: absolute;
  z-index: 999999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
}

.modal-content-container {
  width: 400px;
  height: auto;
  background-color: #fff;
}

.bosch-icon {
  padding: 10px;
  color: #000000;
  font-family: 'bosch-icon';
  font-weight: 400;
}

.pop-up-close {
  cursor: pointer;
  font-size: 17px;
  position: relative;
  padding: 0px;
  left: 90%;
}

.form-alert-icon {
  font-size: 55px;
  color: #fcaf19;
  display: inline-block;
  padding: 0px;
}

.form-issue-icon {
  color: red !important;
  margin-left: -30px;
}

.form-modal-title {
  display: inline-block;
  font-size: 24px;
  font-weight: 100;
  color: #000;
  font-family: 'BoschSans-Light', 'BoschSans-Regular';
  position: relative;
  left: 10px;
  top: -13px;
}

.form-modal-text {
  display: block;
  font-weight: 200;
  color: #000;
  font-family: 'BoschSans-Light', 'BoschSans-Regular';
  margin-right: 40px;
}

.form-cancel-btn {
  border: 1px solid #005691;
  color: #005691;
  border-radius: 0px;
  padding: 8px 45px;
  text-transform: none;
  margin-right: 40px;
  font-family: 'BoschSans', 'Arial';
  font-size: 16px;
}

.form-save-btn {
  border: 1px solid #005691;
  color: #fff;
  background-color: #005691;
  border-radius: 0px;
  padding: 8px 35px;
  text-transform: none;
  margin-right: 20px;
  font-family: 'BoschSans', 'Arial';
  font-size: 16px;
}

/*Ticket Case*/

/* Ticket details */

.steps .step-primary+.step-primary:before {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 145, 1);
  /* hsl(var(--p) / var(--tw-bg-opacity)); */
  --tw-text-opacity: 1;
  color: rgba(0, 86, 145, 1);
  /* hsl(var(--pc) / var(--tw-text-opacity)); */
}

.steps .step-primary:after {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 145, 1);
  /* hsl(var(--p) / var(--tw-bg-opacity)); */
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

/* Ticket details */
/*Filter globe */

.up-arrow {
  @apply border border-gray-400 bg-white after:content-[''] after:absolute after:top-[-7px] after:left-48 after:border after:border-gray-400 after:w-3 after:h-3 after:rotate-45 after:border-b-0 after:border-r-0 after:bg-white;
}

/*Ticket Case*/
.customer-search-wrapper {
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

/*Ticket Case*/

/*
  Custome radio buttons
*/

/* The container */
.radio-btn-container {
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-btn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-btn-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-btn-container input:checked~.checkmark {
  background-color: #005691;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-btn-container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-btn-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
}

.change-customer-pencil-icon {
  font-family: bosch-icon;
  font-weight: bold;
  font-size: 16px;
  color: #005691;
  position: relative;
  display: inline-block;
}

.device-location-pencil-icon {
  font-family: bosch-icon;
  font-size: 24px;
  position: relative;
  display: inline-block;
}

.service-alert-icon {
  display: inline-block;
  font-family: bosch-icon;
  font-weight: 400;
  font-size: 20px;
  content: "\eb0c";
  color: #FCAF17;
  padding: none !important;
}

.loading-services {
  text-align: center;
  font-family: 'BoschSans-Light';
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  width: 100%;
}

/* Loading text animation */
.loading-text-service {
  animation: fade 1.5s infinite; /* Fade animation */
}

/* Fade keyframes */
@keyframes fade {
  0%, 100% {
    opacity: 0.5; /* Start and end with lower opacity */
  }
  50% {
    opacity: 1; /* Full opacity in the middle */
  }
}


/* Loading Ellipsis only for regular containers */
.lds-ellipsis {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 0%;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #005691;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* Loading Ellipsis only for autosuggest containers */
.lds-ellipsis-autosuggest div {
  position: absolute;
  top: 23px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #005691;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis-autosuggest {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  left: 0%;
}

.lds-ellipsis-autosuggest div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis-autosuggest div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-autosuggest div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-autosuggest div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.snackbar-icon-save {
  font-family: bosch-icon;
  font-size: 23px !important;
  float: left;
}

.lds-ellipsis-button {
  display: block;
  position: relative;
  margin:0;
  padding:0;
  width: 100%;
  height:auto;
  z-index:3;
}
.lds-ellipsis-button div {
  position: relative;
  margin:0;
  padding:0;
  width: 12px;
  height:12px;
  border-radius: 100%;
  background: #005691;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  z-index: 5;
}

.lds-ellipsis-button div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.8s infinite;
}

.lds-ellipsis-button div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.8s infinite;
}

.lds-ellipsis-button div:nth-child(3) {
  left: 12px;
  animation: lds-ellipsis2 0.8s infinite;
}

.lds-ellipsis-button div:nth-child(4) {
  left: 16px;
  animation: lds-ellipsis3 0.8s infinite;
}

.btn-blue-gradient {
  font-size: 14px;
  background-image: linear-gradient(to right, rgb(14, 165, 233, 1), rgba(12, 74, 110, 1));
  background-repeat: no-repeat;
  background-attachment: fixed;
  transition-duration: 2s;
}
.btn-blue-gradient:hover {
  background-image: linear-gradient(to left, rgb(14, 165, 233, 1), rgba(12, 74, 110, 1));
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.img-gradient-png {
  filter: sepia(100%) hue-rotate(160deg) saturate(450%);
}
.img-size {
  height: auto;
  max-width: 100%;
}
.m-devices-type {
  color: #535C63;
  padding: 24px 0;
  font-size: 36px;
}

.m-devices-table tr td {
  font-size: 14px;
  color: #333
}

.m-devices-table tr td:nth-child(2) .btn-status{
  color: #005691;
  min-width: 190px;
}


@media only screen and (max-width:900px) {
  .mobile-responsive,
  .m-devices-table tr td{
    display: block!important;
    width:100%!important;
    text-align:left!important;
  }
  .m-devices-table tr td button { min-width:86%}
  .mobile-responsive-table{}
  .mobile-responsive-table table{
    width:100%!important;
  }
}
.aling-icon {
  display: inline-block;
  vertical-align: top;
}
.color-blue {
  color: #005691
}

.solution-table {
  background: rgba(114, 120, 122, 0.1);
  width: 94%;
  min-height: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.header-container{
  border:none;
  height:8vh;
  width:100vw;
  z-index:999999; 
}
.main-container{
  margin-top:-3px;
  border: none;
  height:92.3vh;
  overflow-y:hidden;
  overflow-y:scroll;
}
#go-to-top-button {
	position: fixed;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	right: 24px;
	bottom: 100px;
	background-color: #FFF;
	border: 2px solid rgba(0, 86, 145, 0.25);
	cursor: pointer;
	z-index: 5;
}
#go-to-top-button:hover {
  border-color: #7FC6E7;
  color: #7FC6E7;
}
#go-to-top-button:hover::before {
  color: #7FC6E7;
}
#go-to-top-button::before {
  font-family: bosch-icon;
  font-weight: 400;
  content: "\e296";
  font-size: 36px;
  color: #005691;
  position: relative;
  bottom: 1px;
  left: 8px;
}

.grid-autocomplete-options {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.truncate-autocomplete {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}