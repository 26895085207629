/* Confluence styling for KMS articles*/

table.confluenceTable th.confluenceTh.info,
table.confluenceTable th.confluenceTh.info > p,
table.confluenceTable td.confluenceTd.highlight.info,
table.confluenceTable td.confluenceTd.highlight.info > p,
table.confluenceTable th.confluenceTh.highlight-blue,
table.confluenceTable th.confluenceTh.highlight-blue > p,
table.confluenceTable td.confluenceTd.highlight-blue,
table.confluenceTable td.confluenceTd.highlight-blue > p {
  background-color: #deebff;
}

.confluence-information-macro-note {
  border: 1px solid #fcaf17;
  border-left: 30px solid #fcaf17;
  position: relative;
  padding-left: 20px;
}

.confluence-information-macro-information {
  border: 1px solid #005691;
  border-left: 30px solid #005691;
  position: relative;
  padding-left: 20px;
}

.confluence-information-macro-warning {
  border: 1px solid #ea0016;
  border-left: 30px solid #ea0016;
  position: relative;
  padding-left: 20px;
}

.confluenceTh > .content-wrapper > p > .confluence-embedded-file-wrapper {
  margin-right: 10px;
  content: url('static/shoppingcart.png');
}

.confluenceTh > .content-wrapper {
  font-family: 'Bosch Sans', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.confluence-information-macro {
  margin-top: 10px;
  margin-bottom: 10px;
}

.confluence-information-macro-body {
  padding-left: 10px;
  padding-right: 30px;
  color: #000000;
  font-family: 'Bosch Sans', 'sans-serif';
  text-align: left;
}

.confluence-information-macro-note:before {
  content: url('static/important_20px.png');
  color: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  left: -25px;
  top: 0px;
  bottom: 0px;
}

.confluence-information-macro-information:before {
  content: url('static/simple_20px.png');
  color: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  left: -25px;
  top: 0px;
  bottom: 0px;
}

.confluence-information-macro-warning:before {
  display: flex;
  align-items: center;
  position: absolute;
  left: -25px;
  top: 0px;
  bottom: 0px;
  content: url('static/critical_20px.png');
  color: #fff;
}

.confluenceTable {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.confluenceTh {
  text-align: center !important;
}

.confluenceTable tr:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.confluenceTable th {
  text-align: left;
}

.confluenceTh,
.confluenceTd {
  border: 1px solid #c1c7d0;
  color: #333;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: normal;
  line-height: 24px;
  padding: 7px 10px;
  text-align: left;
  vertical-align: top;
  border: solid 1px #004875;
}

.confluenceTh {
  color: #172b4d;
  background-color: #f4f5f7;
  border: 1px solid #c1c7d0;
  vertical-align: top;
  font-weight: 700;
  font-family: 'BoschSans';
}

.confluenceTh,
.confluenceTd {
  border: 1px solid #c1c7d0;
  padding: 7px 10px;
  vertical-align: top;
  text-align: left;
  min-width: 8px;
}

.confluenceTable {
  border-collapse: collapse;
}

.confluenceTable {
  font-family: 'BoschSans';
  border: 1px solid #c1c7d0;
  color: #333;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: normal;
  line-height: 24px;
  padding: 7px 10px;
  text-align: left;
  vertical-align: top;
  width: 100%;
}

.confluenceTd img {
  width: inherit;
  max-width: 100% !important;
}

.confluenceTh p,
.confluenceTable strong {
  font-weight: 700 !important;
}

.result-details ul {
  color: #333333;
  font-family: 'BoschSans';
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  margin-top: 10px;
  padding-left: 22px;
}

.columns-wrapper .MuiGrid-item {
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
}

.result-details tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.columnMacro {
  display: table-cell;
}

.result-details h2 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0px;
}

.result-details strong {
  font-weight: 400;
}

.highlight-grey {
  background-color: #f4f5f7;
}

.highlight-red {
  background-color: #ffebe5;
}

.highlight-green {
  background-color: #e3fcef;
}

.highlight-yellow {
  background-color: #fffae5;
}

.result-details hr {
  border: 0;
  height: 0;
  border-top: 1px solid #d6d6d6;
  margin-bottom: 16px;
  margin-top: 16px;
}

.result-details code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

.result-title {
  user-select: text;
}

.result-details fieldset span {
  font-size: 21px;
  line-height: 40px;
  color: #333;
}

.result-details fieldset .radio-frame {
  padding-left: 1.65em;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.result-details hr {
  border: 0;
  height: 0;
  border-top: 1px solid #d6d6d6;
  margin-bottom: 16px;
  margin-top: 16px;
}

.aui-lozenge {
  background: #dfe1e6;
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  color: #253858;
  display: inline-block;
  font-weight: bold;
  line-height: 99%;
  margin: 0;
  padding: 2px 15px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.aui-lozenge-success {
  background: #00875a;
  border: 1px solid #00875a;
  color: #fff;
}

.aui-lozenge-error {
  background-color: #bf2600;
  border-color: #bf2600;
  color: #fff;
}

.aui-lozenge-current {
  background-color: #0052cc;
  border-color: #0052cc;
  color: #fff;
}

.date-past {
  font-family: 'Bosch Sans Regular', 'sans-serif';
  font-weight: 300;
  font-size: 14px;
  color: #6c757d;
}

.date-past::before {
  content: url('static/calendar-boxes.png');
  padding-right: 10px;
}

.expand-content {
  margin-left: 38px;
}

.expand-control-text {
  color: #40aadb;
  cursor: pointer;
  font-family: 'Bosch Sans Light', 'sans-serif';
  font-weight: 300;
  font-size: 16px;
  transition: color 0.6s ease;
}

.expand-control-text:hover {
  color: #005691;
}

.expand-control-text:focus ~ .expand-content {
  display: block;
}

.result-details p,
.result-details h1,
.result-details h2,
.result-details h3,
.result-details h4,
.result-details h5,
.result-details h6,
.result-details blockquote,
.result-details pre {
  margin: 10px 0 0 0 !important;
  font-weight: 200 !important;
}

.result-details strong {
  font-weight: 400;
}

.result-details blockquote {
  border-left: 1px solid #ccc;
  margin-left: 0px;
  padding-left: 20px;
}

.result-details address,
.result-details blockquote small,
.result-details pre {
  line-height: 20px;
}

.expand-icon::before {
  font-family: bosch-icon;
  font-weight: bold;
  content: '\e2b9';
  font-size: 16px;
  color: #005691;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
}

.expand-control-show .expand-icon::before {
  transform: rotate(90deg);
}

.expand-hidden {
  /*display: none;*/
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.result-details ul {
  list-style: disc;
}

.result-details ol {
  list-style-type: decimal;
  padding-top: 1rem;
}

.result-details ol ol {
  list-style-type: lower-alpha;
}

.result-details ol ol ol {
  list-style-type: lower-roman;
}

.result-details fieldset {
  border: none;
}

.result-details fieldset {
  padding: 0;
}

.result-head {
  margin-bottom: 10px;
  border: 1px solid #e4e4e4;
}

.expand-control {
  background-color: #ffffff;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}

.result-description .containerScroll p {
  color: #262626;
  font-size: 16px;
  font-family: 'Bosch Sans';
  display: block;
  line-height: 20px;
  text-align: left;
  font-weight: 200;
}
.result-description .containerScroll ul {
  .result-description .containerScroll p img {
    display: inline-block;
  }
  list-style-type: disc !important;
}
.result-description .containerScroll ul li {
  line-height: 20px;
  font-size: 14px;
  font-family: BoschSans-Light, Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.result-description .containerScroll ul li img {
  display: inline-block;
}
.result-description .containerScroll .innerCell ol {
  padding-left: 46px;
}
.result-description .containerScroll .innerCell ol li ol {
  padding: 4px 0;
}

@media (max-width: 1200px) {
  .confluenceTable {
    word-break: break-all;
  }
}
