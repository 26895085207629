@font-face {
  font-family: 'Bosch-Icon';
  src:  url('Bosch-Icon.eot');
  src:  url('Bosch-Icon.eot') format('embedded-opentype'),
    url('Bosch-Icon.ttf') format('truetype'),
    url('Bosch-Icon.woff') format('woff'),
    url('Bosch-Icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="boschicon-"], [class*=" boschicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Bosch-Icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.boschicon-bosch-ic-3d-print:before {
  content: "\e085";
}
.boschicon-bosch-ic-a-to-b:before {
  content: "\e086";
}
.boschicon-bosch-ic-add:before {
  content: "\e087";
}
.boschicon-bosch-ic-address-consumer-data:before {
  content: "\e088";
}
.boschicon-bosch-ic-adress-book:before {
  content: "\e089";
}
.boschicon-bosch-ic-agility:before {
  content: "\e08a";
}
.boschicon-bosch-ic-alarm-clock:before {
  content: "\e08b";
}
.boschicon-bosch-ic-ambulance:before {
  content: "\e08c";
}
.boschicon-bosch-ic-analysis:before {
  content: "\e08d";
}
.boschicon-bosch-ic-anchor:before {
  content: "\e08e";
}
.boschicon-bosch-ic-arrow-down:before {
  content: "\e08f";
}
.boschicon-bosch-ic-arrow-left:before {
  content: "\e090";
}
.boschicon-bosch-ic-arrow-left-down:before {
  content: "\e091";
}
.boschicon-bosch-ic-arrow-left-up:before {
  content: "\e092";
}
.boschicon-bosch-ic-arrow-right:before {
  content: "\e093";
}
.boschicon-bosch-ic-arrow-right-down:before {
  content: "\e094";
}
.boschicon-bosch-ic-arrow-right-up:before {
  content: "\e095";
}
.boschicon-bosch-ic-arrow-turn-left:before {
  content: "\e096";
}
.boschicon-bosch-ic-arrow-turn-right:before {
  content: "\e097";
}
.boschicon-bosch-ic-arrow-up:before {
  content: "\e098";
}
.boschicon-bosch-ic-arrows:before {
  content: "\e099";
}
.boschicon-bosch-ic-arrows-x-y-z:before {
  content: "\e09a";
}
.boschicon-bosch-ic-artificial-intelligence:before {
  content: "\e09b";
}
.boschicon-bosch-ic-assembly-line:before {
  content: "\e09c";
}
.boschicon-bosch-ic-atom:before {
  content: "\e09d";
}
.boschicon-bosch-ic-augmented-reality:before {
  content: "\e09e";
}
.boschicon-bosch-ic-autobod:before {
  content: "\e09f";
}
.boschicon-bosch-ic-back-left:before {
  content: "\e0a0";
}
.boschicon-bosch-ic-back-left-double:before {
  content: "\e0a1";
}
.boschicon-bosch-ic-bar-chart:before {
  content: "\e0a2";
}
.boschicon-bosch-ic-bar-chart-double:before {
  content: "\e0a3";
}
.boschicon-bosch-ic-battery-0:before {
  content: "\e0a4";
}
.boschicon-bosch-ic-battery-1:before {
  content: "\e0a5";
}
.boschicon-bosch-ic-battery-2:before {
  content: "\e0a6";
}
.boschicon-bosch-ic-battery-3:before {
  content: "\e0a7";
}
.boschicon-bosch-ic-battery-4:before {
  content: "\e0a8";
}
.boschicon-bosch-ic-battery-fail:before {
  content: "\e0a9";
}
.boschicon-bosch-ic-battery-li-ion:before {
  content: "\e0aa";
}
.boschicon-bosch-ic-battery-loading:before {
  content: "\e0ab";
}
.boschicon-bosch-ic-bed:before {
  content: "\e0ac";
}
.boschicon-bosch-ic-bicycle:before {
  content: "\e0ad";
}
.boschicon-bosch-ic-bicycle-e:before {
  content: "\e0ae";
}
.boschicon-bosch-ic-blog:before {
  content: "\e0af";
}
.boschicon-bosch-ic-bluetooth:before {
  content: "\e0b0";
}
.boschicon-bosch-ic-bluetooth-le:before {
  content: "\e0b1";
}
.boschicon-bosch-ic-board-speaker:before {
  content: "\e0b2";
}
.boschicon-bosch-ic-boiler-connected:before {
  content: "\e0b3";
}
.boschicon-bosch-ic-book:before {
  content: "\e0b4";
}
.boschicon-bosch-ic-bookmark:before {
  content: "\e0b5";
}
.boschicon-bosch-ic-bookmark-add:before {
  content: "\e0b6";
}
.boschicon-bosch-ic-bookmark-check:before {
  content: "\e0b7";
}
.boschicon-bosch-ic-bookmark-delete:before {
  content: "\e0b8";
}
.boschicon-bosch-ic-books:before {
  content: "\e0b9";
}
.boschicon-bosch-ic-bookshelf:before {
  content: "\e0ba";
}
.boschicon-bosch-ic-box:before {
  content: "\e0bb";
}
.boschicon-bosch-ic-box-closed:before {
  content: "\e0bc";
}
.boschicon-bosch-ic-box-delivery:before {
  content: "\e0bd";
}
.boschicon-bosch-ic-box-delivery-connected:before {
  content: "\e0be";
}
.boschicon-bosch-ic-brake-disk:before {
  content: "\e0bf";
}
.boschicon-bosch-ic-briefcase:before {
  content: "\e0c0";
}
.boschicon-bosch-ic-broom:before {
  content: "\e0c1";
}
.boschicon-bosch-ic-broom-cloud-dirt:before {
  content: "\e0c2";
}
.boschicon-bosch-ic-brush:before {
  content: "\e0c3";
}
.boschicon-bosch-ic-bug:before {
  content: "\e0c4";
}
.boschicon-bosch-ic-building:before {
  content: "\e0c5";
}
.boschicon-bosch-ic-cactus:before {
  content: "\e0c6";
}
.boschicon-bosch-ic-calculate:before {
  content: "\e0c7";
}
.boschicon-bosch-ic-calendar:before {
  content: "\e0c8";
}
.boschicon-bosch-ic-calendar-add:before {
  content: "\e0c9";
}
.boschicon-bosch-ic-calendar-settings:before {
  content: "\e0ca";
}
.boschicon-bosch-ic-call:before {
  content: "\e0cb";
}
.boschicon-bosch-ic-call-add:before {
  content: "\e0cc";
}
.boschicon-bosch-ic-call-remove:before {
  content: "\e0cd";
}
.boschicon-bosch-ic-call-sos:before {
  content: "\e0ce";
}
.boschicon-bosch-ic-call-team:before {
  content: "\e0cf";
}
.boschicon-bosch-ic-call-wifi:before {
  content: "\e0d0";
}
.boschicon-bosch-ic-camera:before {
  content: "\e0d1";
}
.boschicon-bosch-ic-camera-switch:before {
  content: "\e0d2";
}
.boschicon-bosch-ic-car:before {
  content: "\e0d3";
}
.boschicon-bosch-ic-car-clock:before {
  content: "\e0d4";
}
.boschicon-bosch-ic-car-connected:before {
  content: "\e0d5";
}
.boschicon-bosch-ic-car-locator:before {
  content: "\e0d6";
}
.boschicon-bosch-ic-car-mechanic:before {
  content: "\e0d7";
}
.boschicon-bosch-ic-car-rental:before {
  content: "\e0d8";
}
.boschicon-bosch-ic-car-seat-connected:before {
  content: "\e0d9";
}
.boschicon-bosch-ic-car-side:before {
  content: "\e0da";
}
.boschicon-bosch-ic-car-side-user:before {
  content: "\e0db";
}
.boschicon-bosch-ic-cart:before {
  content: "\e0dc";
}
.boschicon-bosch-ic-certificate:before {
  content: "\e0dd";
}
.boschicon-bosch-ic-certificate-pki:before {
  content: "\e0de";
}
.boschicon-bosch-ic-change:before {
  content: "\e0df";
}
.boschicon-bosch-ic-chart-bar:before {
  content: "\e0e0";
}
.boschicon-bosch-ic-chart-check:before {
  content: "\e0e1";
}
.boschicon-bosch-ic-chart-dummy:before {
  content: "\e0e2";
}
.boschicon-bosch-ic-chart-line:before {
  content: "\e0e3";
}
.boschicon-bosch-ic-chat:before {
  content: "\e0e4";
}
.boschicon-bosch-ic-chat-add:before {
  content: "\e0e5";
}
.boschicon-bosch-ic-chat-question-answer:before {
  content: "\e0e6";
}
.boschicon-bosch-ic-chat-remove:before {
  content: "\e0e7";
}
.boschicon-bosch-ic-checklist:before {
  content: "\e0e8";
}
.boschicon-bosch-ic-checkmark:before {
  content: "\e0e9";
}
.boschicon-bosch-ic-chip:before {
  content: "\e0ea";
}
.boschicon-bosch-ic-circle-segment:before {
  content: "\e0eb";
}
.boschicon-bosch-ic-clear-all:before {
  content: "\e0ec";
}
.boschicon-bosch-ic-clipboard:before {
  content: "\e0ed";
}
.boschicon-bosch-ic-clipboard-list:before {
  content: "\e0ee";
}
.boschicon-bosch-ic-clock:before {
  content: "\e0ef";
}
.boschicon-bosch-ic-close:before {
  content: "\e0f0";
}
.boschicon-bosch-ic-close-all:before {
  content: "\e0f1";
}
.boschicon-bosch-ic-cloud:before {
  content: "\e0f2";
}
.boschicon-bosch-ic-cloud-co2:before {
  content: "\e0f3";
}
.boschicon-bosch-ic-cloud-download:before {
  content: "\e0f4";
}
.boschicon-bosch-ic-cloud-nox:before {
  content: "\e0f5";
}
.boschicon-bosch-ic-cloud-upload:before {
  content: "\e0f6";
}
.boschicon-bosch-ic-club-arm-chair:before {
  content: "\e0f7";
}
.boschicon-bosch-ic-coffee-break:before {
  content: "\e0f8";
}
.boschicon-bosch-ic-coin-currency:before {
  content: "\e0f9";
}
.boschicon-bosch-ic-coin-dollar:before {
  content: "\e0fa";
}
.boschicon-bosch-ic-colorpicker:before {
  content: "\e0fb";
}
.boschicon-bosch-ic-colors:before {
  content: "\e0fc";
}
.boschicon-bosch-ic-command:before {
  content: "\e0fd";
}
.boschicon-bosch-ic-communicator:before {
  content: "\e0fe";
}
.boschicon-bosch-ic-compare:before {
  content: "\e0ff";
}
.boschicon-bosch-ic-components:before {
  content: "\e100";
}
.boschicon-bosch-ic-components-available:before {
  content: "\e101";
}
.boschicon-bosch-ic-components-reservation:before {
  content: "\e102";
}
.boschicon-bosch-ic-concierge-bell:before {
  content: "\e103";
}
.boschicon-bosch-ic-configuration:before {
  content: "\e104";
}
.boschicon-bosch-ic-confirmation:before {
  content: "\e105";
}
.boschicon-bosch-ic-connection-off:before {
  content: "\e106";
}
.boschicon-bosch-ic-connection-on:before {
  content: "\e107";
}
.boschicon-bosch-ic-connectivity:before {
  content: "\e108";
}
.boschicon-bosch-ic-construction:before {
  content: "\e109";
}
.boschicon-bosch-ic-consumer-goods:before {
  content: "\e10a";
}
.boschicon-bosch-ic-contract:before {
  content: "\e10b";
}
.boschicon-bosch-ic-copy:before {
  content: "\e10c";
}
.boschicon-bosch-ic-cube:before {
  content: "\e10d";
}
.boschicon-bosch-ic-cube-arrows:before {
  content: "\e10e";
}
.boschicon-bosch-ic-cube-filled:before {
  content: "\e10f";
}
.boschicon-bosch-ic-cube-shock:before {
  content: "\e110";
}
.boschicon-bosch-ic-cube-stacked:before {
  content: "\e111";
}
.boschicon-bosch-ic-customerservice:before {
  content: "\e112";
}
.boschicon-bosch-ic-cutlery:before {
  content: "\e113";
}
.boschicon-bosch-ic-damper:before {
  content: "\e114";
}
.boschicon-bosch-ic-danger-flash:before {
  content: "\e115";
}
.boschicon-bosch-ic-data-loss:before {
  content: "\e116";
}
.boschicon-bosch-ic-dealer-details:before {
  content: "\e117";
}
.boschicon-bosch-ic-delete:before {
  content: "\e118";
}
.boschicon-bosch-ic-delivery:before {
  content: "\e119";
}
.boschicon-bosch-ic-denied:before {
  content: "\e11a";
}
.boschicon-bosch-ic-desktop:before {
  content: "\e11b";
}
.boschicon-bosch-ic-desktop-application-2d:before {
  content: "\e11c";
}
.boschicon-bosch-ic-desktop-application-3d:before {
  content: "\e11d";
}
.boschicon-bosch-ic-development:before {
  content: "\e11e";
}
.boschicon-bosch-ic-devices:before {
  content: "\e11f";
}
.boschicon-bosch-ic-devices-mobile:before {
  content: "\e120";
}
.boschicon-bosch-ic-directory:before {
  content: "\e121";
}
.boschicon-bosch-ic-dna:before {
  content: "\e122";
}
.boschicon-bosch-ic-do-not-disturb:before {
  content: "\e123";
}
.boschicon-bosch-ic-document:before {
  content: "\e124";
}
.boschicon-bosch-ic-document-add:before {
  content: "\e125";
}
.boschicon-bosch-ic-document-arrow-down:before {
  content: "\e126";
}
.boschicon-bosch-ic-document-arrow-up:before {
  content: "\e127";
}
.boschicon-bosch-ic-document-audio:before {
  content: "\e128";
}
.boschicon-bosch-ic-document-check:before {
  content: "\e129";
}
.boschicon-bosch-ic-document-cloud:before {
  content: "\e12a";
}
.boschicon-bosch-ic-document-copy:before {
  content: "\e12b";
}
.boschicon-bosch-ic-document-delete:before {
  content: "\e12c";
}
.boschicon-bosch-ic-document-doc:before {
  content: "\e12d";
}
.boschicon-bosch-ic-document-edit:before {
  content: "\e12e";
}
.boschicon-bosch-ic-document-error:before {
  content: "\e12f";
}
.boschicon-bosch-ic-document-locked:before {
  content: "\e130";
}
.boschicon-bosch-ic-document-log:before {
  content: "\e131";
}
.boschicon-bosch-ic-document-one:before {
  content: "\e132";
}
.boschicon-bosch-ic-document-pdf:before {
  content: "\e133";
}
.boschicon-bosch-ic-document-plain:before {
  content: "\e134";
}
.boschicon-bosch-ic-document-plain-add:before {
  content: "\e135";
}
.boschicon-bosch-ic-document-plain-delete:before {
  content: "\e136";
}
.boschicon-bosch-ic-document-ppt:before {
  content: "\e137";
}
.boschicon-bosch-ic-document-pub:before {
  content: "\e138";
}
.boschicon-bosch-ic-document-rtf:before {
  content: "\e139";
}
.boschicon-bosch-ic-document-save-to:before {
  content: "\e13a";
}
.boschicon-bosch-ic-document-search:before {
  content: "\e13b";
}
.boschicon-bosch-ic-document-settings:before {
  content: "\e13c";
}
.boschicon-bosch-ic-document-test:before {
  content: "\e13d";
}
.boschicon-bosch-ic-document-text:before {
  content: "\e13e";
}
.boschicon-bosch-ic-document-txt:before {
  content: "\e13f";
}
.boschicon-bosch-ic-document-vsd:before {
  content: "\e140";
}
.boschicon-bosch-ic-document-xls:before {
  content: "\e141";
}
.boschicon-bosch-ic-document-xml:before {
  content: "\e142";
}
.boschicon-bosch-ic-document-zip:before {
  content: "\e143";
}
.boschicon-bosch-ic-dome:before {
  content: "\e144";
}
.boschicon-bosch-ic-door:before {
  content: "\e145";
}
.boschicon-bosch-ic-door-sensor:before {
  content: "\e146";
}
.boschicon-bosch-ic-down:before {
  content: "\e147";
}
.boschicon-bosch-ic-down-double:before {
  content: "\e148";
}
.boschicon-bosch-ic-download:before {
  content: "\e149";
}
.boschicon-bosch-ic-drag-handle:before {
  content: "\e14a";
}
.boschicon-bosch-ic-dragdrop:before {
  content: "\e14b";
}
.boschicon-bosch-ic-drop:before {
  content: "\e14c";
}
.boschicon-bosch-ic-e-charging:before {
  content: "\e14d";
}
.boschicon-bosch-ic-e-mobility:before {
  content: "\e14e";
}
.boschicon-bosch-ic-ear-off-disabled-light:before {
  content: "\e14f";
}
.boschicon-bosch-ic-ear-on:before {
  content: "\e150";
}
.boschicon-bosch-ic-eco-system:before {
  content: "\e151";
}
.boschicon-bosch-ic-edit:before {
  content: "\e152";
}
.boschicon-bosch-ic-education:before {
  content: "\e153";
}
.boschicon-bosch-ic-efficiency:before {
  content: "\e154";
}
.boschicon-bosch-ic-elevator:before {
  content: "\e155";
}
.boschicon-bosch-ic-elevator-alarm:before {
  content: "\e156";
}
.boschicon-bosch-ic-elevator-cloud:before {
  content: "\e157";
}
.boschicon-bosch-ic-elevator-headset:before {
  content: "\e158";
}
.boschicon-bosch-ic-elevator-service:before {
  content: "\e159";
}
.boschicon-bosch-ic-emergency-exit:before {
  content: "\e15a";
}
.boschicon-bosch-ic-emoji-happy:before {
  content: "\e15b";
}
.boschicon-bosch-ic-emoji-neutral:before {
  content: "\e15c";
}
.boschicon-bosch-ic-emoji-sad:before {
  content: "\e15d";
}
.boschicon-bosch-ic-emoji-super-happy:before {
  content: "\e15e";
}
.boschicon-bosch-ic-emoji-very-sad:before {
  content: "\e15f";
}
.boschicon-bosch-ic-eu-energy-label:before {
  content: "\e160";
}
.boschicon-bosch-ic-excavator:before {
  content: "\e161";
}
.boschicon-no-icon:before {
  content: "\e162";
}
.boschicon-bosch-ic-exit:before {
  content: "\e163";
}
.boschicon-bosch-ic-expansion-arrows:before {
  content: "\e164";
}
.boschicon-bosch-ic-explosion:before {
  content: "\e165";
}
.boschicon-bosch-ic-export:before {
  content: "\e166";
}
.boschicon-bosch-ic-externallink:before {
  content: "\e167";
}
.boschicon-bosch-ic-fast-forward:before {
  content: "\e168";
}
.boschicon-bosch-ic-faucet:before {
  content: "\e169";
}
.boschicon-bosch-ic-favorites:before {
  content: "\e16a";
}
.boschicon-bosch-ic-fax:before {
  content: "\e16b";
}
.boschicon-bosch-ic-female:before {
  content: "\e16c";
}
.boschicon-bosch-ic-film:before {
  content: "\e16d";
}
.boschicon-bosch-ic-filter:before {
  content: "\e16e";
}
.boschicon-bosch-ic-filter-success:before {
  content: "\e16f";
}
.boschicon-bosch-ic-fingerprint:before {
  content: "\e170";
}
.boschicon-bosch-ic-fire:before {
  content: "\e171";
}
.boschicon-bosch-ic-fire-emergency:before {
  content: "\e172";
}
.boschicon-bosch-ic-fireworks:before {
  content: "\e173";
}
.boschicon-bosch-ic-first-aid:before {
  content: "\e174";
}
.boschicon-bosch-ic-first-aid-cross:before {
  content: "\e175";
}
.boschicon-bosch-ic-fit-to-sceen:before {
  content: "\e176";
}
.boschicon-bosch-ic-flag:before {
  content: "\e177";
}
.boschicon-bosch-ic-flash:before {
  content: "\e178";
}
.boschicon-bosch-ic-flask:before {
  content: "\e179";
}
.boschicon-bosch-ic-flexpress:before {
  content: "\e17a";
}
.boschicon-bosch-ic-folder:before {
  content: "\e17b";
}
.boschicon-bosch-ic-folder-download:before {
  content: "\e17c";
}
.boschicon-bosch-ic-folder-open:before {
  content: "\e17d";
}
.boschicon-bosch-ic-folder-upload:before {
  content: "\e17e";
}
.boschicon-bosch-ic-fork-lift:before {
  content: "\e17f";
}
.boschicon-bosch-ic-fork-lift-locator:before {
  content: "\e180";
}
.boschicon-bosch-ic-forward-right:before {
  content: "\e181";
}
.boschicon-bosch-ic-forward-right-double:before {
  content: "\e182";
}
.boschicon-bosch-ic-full-empty:before {
  content: "\e183";
}
.boschicon-bosch-ic-fullscreen:before {
  content: "\e184";
}
.boschicon-bosch-ic-fullscreen-arrows:before {
  content: "\e185";
}
.boschicon-bosch-ic-fullscreen-exit:before {
  content: "\e186";
}
.boschicon-bosch-ic-g-arrow-down:before {
  content: "\e187";
}
.boschicon-bosch-ic-g-arrow-up:before {
  content: "\e188";
}
.boschicon-bosch-ic-generator:before {
  content: "\e189";
}
.boschicon-bosch-ic-gift:before {
  content: "\e18a";
}
.boschicon-bosch-ic-glance:before {
  content: "\e18b";
}
.boschicon-bosch-ic-glas-plate:before {
  content: "\e18c";
}
.boschicon-bosch-ic-globe:before {
  content: "\e18d";
}
.boschicon-bosch-ic-globe-arrow:before {
  content: "\e18e";
}
.boschicon-bosch-ic-glossary:before {
  content: "\e18f";
}
.boschicon-bosch-ic-grid-view:before {
  content: "\e190";
}
.boschicon-bosch-ic-hammer:before {
  content: "\e191";
}
.boschicon-bosch-ic-hand:before {
  content: "\e192";
}
.boschicon-bosch-ic-hand-motion:before {
  content: "\e193";
}
.boschicon-bosch-ic-hand-motion-off-disabled-light:before {
  content: "\e194";
}
.boschicon-bosch-ic-handlewithcare:before {
  content: "\e195";
}
.boschicon-bosch-ic-handover-add:before {
  content: "\e196";
}
.boschicon-bosch-ic-handover-check:before {
  content: "\e197";
}
.boschicon-bosch-ic-handover-remove:before {
  content: "\e198";
}
.boschicon-bosch-ic-hanger:before {
  content: "\e199";
}
.boschicon-bosch-ic-health:before {
  content: "\e19a";
}
.boschicon-bosch-ic-heart:before {
  content: "\e19b";
}
.boschicon-bosch-ic-history:before {
  content: "\e19c";
}
.boschicon-bosch-ic-home:before {
  content: "\e19d";
}
.boschicon-bosch-ic-home-locator:before {
  content: "\e19e";
}
.boschicon-bosch-ic-hourglass:before {
  content: "\e19f";
}
.boschicon-bosch-ic-hydrant:before {
  content: "\e1a0";
}
.boschicon-bosch-ic-ice:before {
  content: "\e1a1";
}
.boschicon-bosch-ic-imagery:before {
  content: "\e1a2";
}
.boschicon-bosch-ic-imagery-add:before {
  content: "\e1a3";
}
.boschicon-bosch-ic-imagery-remove:before {
  content: "\e1a4";
}
.boschicon-bosch-ic-import:before {
  content: "\e1a5";
}
.boschicon-bosch-ic-imprint:before {
  content: "\e1a6";
}
.boschicon-bosch-ic-impulse:before {
  content: "\e1a7";
}
.boschicon-bosch-ic-industry:before {
  content: "\e1a8";
}
.boschicon-bosch-ic-industry-clock:before {
  content: "\e1a9";
}
.boschicon-bosch-ic-industry-connected:before {
  content: "\e1aa";
}
.boschicon-bosch-ic-info:before {
  content: "\e1ab";
}
.boschicon-bosch-ic-info-i:before {
  content: "\e1ac";
}
.boschicon-bosch-ic-interval:before {
  content: "\e1ad";
}
.boschicon-bosch-ic-it-device:before {
  content: "\e1ae";
}
.boschicon-bosch-ic-jewel:before {
  content: "\e1af";
}
.boschicon-bosch-ic-keyboard:before {
  content: "\e1b0";
}
.boschicon-bosch-ic-label:before {
  content: "\e1b1";
}
.boschicon-bosch-ic-laptop:before {
  content: "\e1b2";
}
.boschicon-bosch-ic-laser:before {
  content: "\e1b3";
}
.boschicon-bosch-ic-layout:before {
  content: "\e1b4";
}
.boschicon-bosch-ic-leaf:before {
  content: "\e1b5";
}
.boschicon-bosch-ic-led:before {
  content: "\e1b6";
}
.boschicon-bosch-ic-brick:before {
  content: "\e1b7";
}
.boschicon-bosch-ic-brick-slanting-1:before {
  content: "\e1b8";
}
.boschicon-bosch-ic-brick-slanting-2:before {
  content: "\e1b9";
}
.boschicon-bosch-ic-less-minimize:before {
  content: "\e1ba";
}
.boschicon-bosch-ic-lightbulb:before {
  content: "\e1bb";
}
.boschicon-bosch-ic-lightbulb-off:before {
  content: "\e1bc";
}
.boschicon-bosch-ic-line-chart:before {
  content: "\e1bd";
}
.boschicon-bosch-ic-link:before {
  content: "\e1be";
}
.boschicon-bosch-ic-link-broken:before {
  content: "\e1bf";
}
.boschicon-bosch-ic-list-view:before {
  content: "\e1c0";
}
.boschicon-bosch-ic-list-view-mobile:before {
  content: "\e1c1";
}
.boschicon-bosch-ic-livechat:before {
  content: "\e1c2";
}
.boschicon-bosch-ic-locator:before {
  content: "\e1c3";
}
.boschicon-bosch-ic-locator-ip-connected:before {
  content: "\e1c4";
}
.boschicon-bosch-ic-locator-ip-disconnected:before {
  content: "\e1c5";
}
.boschicon-bosch-ic-locator-spot:before {
  content: "\e1c6";
}
.boschicon-bosch-ic-locator-spot-check:before {
  content: "\e1c7";
}
.boschicon-bosch-ic-lock-closed:before {
  content: "\e1c8";
}
.boschicon-bosch-ic-lock-open:before {
  content: "\e1c9";
}
.boschicon-bosch-ic-login:before {
  content: "\e1ca";
}
.boschicon-bosch-ic-logistics:before {
  content: "\e1cb";
}
.boschicon-bosch-ic-logout:before {
  content: "\e1cc";
}
.boschicon-bosch-ic-machine:before {
  content: "\e1cd";
}
.boschicon-bosch-ic-magnet:before {
  content: "\e1ce";
}
.boschicon-bosch-ic-mail:before {
  content: "\e1cf";
}
.boschicon-bosch-ic-mail-forward:before {
  content: "\e1d0";
}
.boschicon-bosch-ic-mail-open:before {
  content: "\e1d1";
}
.boschicon-bosch-ic-mail-out:before {
  content: "\e1d2";
}
.boschicon-bosch-ic-mail-reply:before {
  content: "\e1d3";
}
.boschicon-bosch-ic-male:before {
  content: "\e1d4";
}
.boschicon-bosch-ic-map:before {
  content: "\e1d5";
}
.boschicon-bosch-ic-material-add:before {
  content: "\e1d6";
}
.boschicon-bosch-ic-material-check:before {
  content: "\e1d7";
}
.boschicon-bosch-ic-material-remove:before {
  content: "\e1d8";
}
.boschicon-bosch-ic-maximize:before {
  content: "\e1d9";
}
.boschicon-bosch-ic-mechanic:before {
  content: "\e1da";
}
.boschicon-bosch-ic-megaphone:before {
  content: "\e1db";
}
.boschicon-bosch-ic-message:before {
  content: "\e1dc";
}
.boschicon-bosch-ic-mic:before {
  content: "\e1dd";
}
.boschicon-bosch-ic-microphone:before {
  content: "\e1de";
}
.boschicon-bosch-ic-microphone-classic:before {
  content: "\e1df";
}
.boschicon-bosch-ic-minimize:before {
  content: "\e1e0";
}
.boschicon-bosch-ic-money-currency:before {
  content: "\e1e1";
}
.boschicon-bosch-ic-money-dollar:before {
  content: "\e1e2";
}
.boschicon-bosch-ic-moneybag-currency:before {
  content: "\e1e3";
}
.boschicon-bosch-ic-moneybag-dollar:before {
  content: "\e1e4";
}
.boschicon-bosch-ic-monument:before {
  content: "\e1e5";
}
.boschicon-bosch-ic-moon:before {
  content: "\e1e6";
}
.boschicon-bosch-ic-motorcycle:before {
  content: "\e1e7";
}
.boschicon-bosch-ic-motorcycle-side-car:before {
  content: "\e1e8";
}
.boschicon-bosch-ic-motorcycle-side:before {
  content: "\e1e9";
}
.boschicon-bosch-ic-mouse:before {
  content: "\e1ea";
}
.boschicon-bosch-ic-mouse-left:before {
  content: "\e1eb";
}
.boschicon-bosch-ic-mouse-right:before {
  content: "\e1ec";
}
.boschicon-bosch-ic-mouth:before {
  content: "\e1ed";
}
.boschicon-bosch-ic-my-product:before {
  content: "\e1ee";
}
.boschicon-bosch-ic-new-way-work:before {
  content: "\e1ef";
}
.boschicon-bosch-ic-newsletter:before {
  content: "\e1f0";
}
.boschicon-bosch-ic-newspaper:before {
  content: "\e1f1";
}
.boschicon-bosch-ic-nose:before {
  content: "\e1f2";
}
.boschicon-bosch-ic-notepad:before {
  content: "\e1f3";
}
.boschicon-bosch-ic-notepad-edit:before {
  content: "\e1f4";
}
.boschicon-bosch-ic-notification:before {
  content: "\e1f5";
}
.boschicon-bosch-ic-notification-active:before {
  content: "\e1f6";
}
.boschicon-bosch-ic-notification-add:before {
  content: "\e1f7";
}
.boschicon-bosch-ic-notification-remove:before {
  content: "\e1f8";
}
.boschicon-bosch-ic-oil-car:before {
  content: "\e1f9";
}
.boschicon-bosch-ic-omnichannel:before {
  content: "\e1fa";
}
.boschicon-bosch-ic-options:before {
  content: "\e1fb";
}
.boschicon-bosch-ic-origami-boat:before {
  content: "\e1fc";
}
.boschicon-bosch-ic-pallete-car:before {
  content: "\e1fd";
}
.boschicon-bosch-ic-pallete-car-connected:before {
  content: "\e1fe";
}
.boschicon-bosch-ic-panel:before {
  content: "\e1ff";
}
.boschicon-bosch-ic-paperclip:before {
  content: "\e200";
}
.boschicon-bosch-ic-paperplane:before {
  content: "\e201";
}
.boschicon-bosch-ic-parking:before {
  content: "\e202";
}
.boschicon-bosch-ic-pause:before {
  content: "\e203";
}
.boschicon-bosch-ic-payment:before {
  content: "\e204";
}
.boschicon-bosch-ic-people:before {
  content: "\e205";
}
.boschicon-bosch-ic-petrol-station:before {
  content: "\e206";
}
.boschicon-bosch-ic-piggybank:before {
  content: "\e207";
}
.boschicon-bosch-ic-pin-classic:before {
  content: "\e208";
}
.boschicon-bosch-ic-pin-modern:before {
  content: "\e209";
}
.boschicon-bosch-ic-plane-side:before {
  content: "\e20a";
}
.boschicon-bosch-ic-play:before {
  content: "\e20b";
}
.boschicon-bosch-ic-plug-12v:before {
  content: "\e20c";
}
.boschicon-bosch-ic-podium-speaker:before {
  content: "\e20d";
}
.boschicon-bosch-ic-police:before {
  content: "\e20e";
}
.boschicon-bosch-ic-poop:before {
  content: "\e20f";
}
.boschicon-bosch-ic-post-it:before {
  content: "\e210";
}
.boschicon-bosch-ic-postal-code:before {
  content: "\e211";
}
.boschicon-bosch-ic-power:before {
  content: "\e212";
}
.boschicon-bosch-ic-print:before {
  content: "\e213";
}
.boschicon-bosch-ic-prototyping:before {
  content: "\e214";
}
.boschicon-bosch-ic-puzzle:before {
  content: "\e215";
}
.boschicon-bosch-ic-quad:before {
  content: "\e216";
}
.boschicon-bosch-ic-question:before {
  content: "\e217";
}
.boschicon-bosch-ic-radar:before {
  content: "\e218";
}
.boschicon-bosch-ic-radio:before {
  content: "\e219";
}
.boschicon-bosch-ic-radiotower:before {
  content: "\e21a";
}
.boschicon-bosch-ic-redirect:before {
  content: "\e21b";
}
.boschicon-bosch-ic-redo:before {
  content: "\e21c";
}
.boschicon-bosch-ic-reference:before {
  content: "\e21d";
}
.boschicon-bosch-ic-refresh:before {
  content: "\e21e";
}
.boschicon-bosch-ic-refresh-cloud:before {
  content: "\e21f";
}
.boschicon-bosch-ic-registration:before {
  content: "\e220";
}
.boschicon-bosch-ic-remote:before {
  content: "\e221";
}
.boschicon-bosch-ic-reset:before {
  content: "\e222";
}
.boschicon-bosch-ic-resolution:before {
  content: "\e223";
}
.boschicon-bosch-ic-robot:before {
  content: "\e224";
}
.boschicon-bosch-ic-robot-connected:before {
  content: "\e225";
}
.boschicon-bosch-ic-robothead:before {
  content: "\e226";
}
.boschicon-bosch-ic-rocket:before {
  content: "\e227";
}
.boschicon-bosch-ic-rotation:before {
  content: "\e228";
}
.boschicon-bosch-ic-rotation-360:before {
  content: "\e229";
}
.boschicon-bosch-ic-rotation-x-left:before {
  content: "\e22a";
}
.boschicon-bosch-ic-rotation-x-right:before {
  content: "\e22b";
}
.boschicon-bosch-ic-rotation-y-down:before {
  content: "\e22c";
}
.boschicon-bosch-ic-rotation-y-up:before {
  content: "\e22d";
}
.boschicon-bosch-ic-route:before {
  content: "\e22e";
}
.boschicon-bosch-ic-ruler:before {
  content: "\e22f";
}
.boschicon-bosch-ic-ruler-pen:before {
  content: "\e230";
}
.boschicon-bosch-ic-satellite:before {
  content: "\e231";
}
.boschicon-bosch-ic-save:before {
  content: "\e232";
}
.boschicon-bosch-ic-scale:before {
  content: "\e233";
}
.boschicon-bosch-ic-scissors:before {
  content: "\e234";
}
.boschicon-bosch-ic-search:before {
  content: "\e235";
}
.boschicon-bosch-ic-security:before {
  content: "\e236";
}
.boschicon-bosch-ic-security-check:before {
  content: "\e237";
}
.boschicon-bosch-ic-security-user:before {
  content: "\e238";
}
.boschicon-bosch-ic-sensor:before {
  content: "\e239";
}
.boschicon-bosch-ic-server:before {
  content: "\e23a";
}
.boschicon-bosch-ic-server-expansion:before {
  content: "\e23b";
}
.boschicon-bosch-ic-server-rate:before {
  content: "\e23c";
}
.boschicon-bosch-ic-service-agent:before {
  content: "\e23d";
}
.boschicon-bosch-ic-service-time:before {
  content: "\e23e";
}
.boschicon-bosch-ic-settings:before {
  content: "\e23f";
}
.boschicon-bosch-ic-settings-arrows:before {
  content: "\e240";
}
.boschicon-bosch-ic-settings-connected:before {
  content: "\e241";
}
.boschicon-bosch-ic-share:before {
  content: "\e242";
}
.boschicon-bosch-ic-shield-stop:before {
  content: "\e243";
}
.boschicon-bosch-ic-ship:before {
  content: "\e244";
}
.boschicon-bosch-ic-ship-side:before {
  content: "\e245";
}
.boschicon-bosch-ic-shoppingcart:before {
  content: "\e246";
}
.boschicon-bosch-ic-shoppingcart-add:before {
  content: "\e247";
}
.boschicon-bosch-ic-shoppingcart-switch:before {
  content: "\e248";
}
.boschicon-bosch-ic-signal-full:before {
  content: "\e249";
}
.boschicon-bosch-ic-signal-half:before {
  content: "\e24a";
}
.boschicon-bosch-ic-signal-lost:before {
  content: "\e24b";
}
.boschicon-bosch-ic-signpost:before {
  content: "\e24c";
}
.boschicon-bosch-ic-simcard:before {
  content: "\e24d";
}
.boschicon-bosch-ic-skyscraper:before {
  content: "\e24e";
}
.boschicon-bosch-ic-smartcity:before {
  content: "\e24f";
}
.boschicon-bosch-ic-smarthome:before {
  content: "\e250";
}
.boschicon-bosch-ic-smartphone:before {
  content: "\e251";
}
.boschicon-bosch-ic-smartphone-acoustic:before {
  content: "\e252";
}
.boschicon-bosch-ic-smartphone-arrow-right:before {
  content: "\e253";
}
.boschicon-bosch-ic-smartphone-arrows-x-y-z:before {
  content: "\e254";
}
.boschicon-bosch-ic-smartphone-clock:before {
  content: "\e255";
}
.boschicon-bosch-ic-smartphone-cloud:before {
  content: "\e256";
}
.boschicon-bosch-ic-smartphone-loading-wheel:before {
  content: "\e257";
}
.boschicon-bosch-ic-smartphone-smashed:before {
  content: "\e258";
}
.boschicon-bosch-ic-smartphone-vibration:before {
  content: "\e259";
}
.boschicon-bosch-ic-smartphone-z:before {
  content: "\e25a";
}
.boschicon-bosch-ic-smartwatch-connected:before {
  content: "\e25b";
}
.boschicon-bosch-ic-spare-parts:before {
  content: "\e25c";
}
.boschicon-bosch-ic-spark:before {
  content: "\e25d";
}
.boschicon-bosch-ic-speaker-acoustic:before {
  content: "\e25e";
}
.boschicon-bosch-ic-speedometer:before {
  content: "\e25f";
}
.boschicon-bosch-ic-stadium:before {
  content: "\e260";
}
.boschicon-bosch-ic-stairs:before {
  content: "\e261";
}
.boschicon-bosch-ic-stamp:before {
  content: "\e262";
}
.boschicon-bosch-ic-standby:before {
  content: "\e263";
}
.boschicon-bosch-ic-steering-wheel:before {
  content: "\e264";
}
.boschicon-bosch-ic-steering-wheel-connected:before {
  content: "\e265";
}
.boschicon-bosch-ic-steering-wheel-hands:before {
  content: "\e266";
}
.boschicon-bosch-ic-steps:before {
  content: "\e267";
}
.boschicon-bosch-ic-stethoscope:before {
  content: "\e268";
}
.boschicon-bosch-ic-stop:before {
  content: "\e269";
}
.boschicon-bosch-ic-stopwatch:before {
  content: "\e26a";
}
.boschicon-bosch-ic-storage-local:before {
  content: "\e26b";
}
.boschicon-bosch-ic-structure:before {
  content: "\e26c";
}
.boschicon-bosch-ic-subtitles:before {
  content: "\e26d";
}
.boschicon-bosch-ic-subtitles-off-light:before {
  content: "\e26e";
}
.boschicon-bosch-ic-summary:before {
  content: "\e26f";
}
.boschicon-bosch-ic-sun:before {
  content: "\e270";
}
.boschicon-bosch-ic-sun-half-filled:before {
  content: "\e271";
}
.boschicon-bosch-ic-sunshade-table:before {
  content: "\e272";
}
.boschicon-bosch-ic-surveillance:before {
  content: "\e273";
}
.boschicon-bosch-ic-table-chairs:before {
  content: "\e274";
}
.boschicon-bosch-ic-tablet:before {
  content: "\e275";
}
.boschicon-bosch-ic-team:before {
  content: "\e276";
}
.boschicon-bosch-ic-team-lightbulb:before {
  content: "\e277";
}
.boschicon-bosch-ic-technical-service:before {
  content: "\e278";
}
.boschicon-bosch-ic-temperature:before {
  content: "\e279";
}
.boschicon-bosch-ic-temperature-high:before {
  content: "\e27a";
}
.boschicon-bosch-ic-temperature-low:before {
  content: "\e27b";
}
.boschicon-bosch-ic-theater:before {
  content: "\e27c";
}
.boschicon-bosch-ic-thumb-down:before {
  content: "\e27d";
}
.boschicon-bosch-ic-thumb-up:before {
  content: "\e27e";
}
.boschicon-bosch-ic-ticket:before {
  content: "\e27f";
}
.boschicon-bosch-ic-touch:before {
  content: "\e280";
}
.boschicon-bosch-ic-touch-sos:before {
  content: "\e281";
}
.boschicon-bosch-ic-towing-truck:before {
  content: "\e282";
}
.boschicon-bosch-ic-train:before {
  content: "\e283";
}
.boschicon-bosch-ic-train-side:before {
  content: "\e284";
}
.boschicon-bosch-ic-transport-movements:before {
  content: "\e285";
}
.boschicon-bosch-ic-transscript:before {
  content: "\e286";
}
.boschicon-bosch-ic-transscript-off:before {
  content: "\e287";
}
.boschicon-bosch-ic-transscript-on:before {
  content: "\e288";
}
.boschicon-bosch-ic-tree:before {
  content: "\e289";
}
.boschicon-bosch-ic-tricycle:before {
  content: "\e28a";
}
.boschicon-bosch-ic-trolley-empty:before {
  content: "\e28b";
}
.boschicon-bosch-ic-trolley-filled-box:before {
  content: "\e28c";
}
.boschicon-bosch-ic-trolly-filled-files:before {
  content: "\e28d";
}
.boschicon-bosch-ic-trophy:before {
  content: "\e28e";
}
.boschicon-bosch-ic-truck:before {
  content: "\e28f";
}
.boschicon-bosch-ic-tuktuk:before {
  content: "\e290";
}
.boschicon-bosch-ic-tune:before {
  content: "\e291";
}
.boschicon-bosch-ic-typography:before {
  content: "\e292";
}
.boschicon-bosch-ic-ui:before {
  content: "\e293";
}
.boschicon-bosch-ic-umbrella:before {
  content: "\e294";
}
.boschicon-bosch-ic-undo:before {
  content: "\e295";
}
.boschicon-bosch-ic-up:before {
  content: "\e296";
}
.boschicon-bosch-ic-up-double:before {
  content: "\e297";
}
.boschicon-bosch-ic-up-down:before {
  content: "\e298";
}
.boschicon-bosch-ic-upload:before {
  content: "\e299";
}
.boschicon-bosch-ic-usb:before {
  content: "\e29a";
}
.boschicon-bosch-ic-user:before {
  content: "\e29b";
}
.boschicon-bosch-ic-user-advanced:before {
  content: "\e29c";
}
.boschicon-bosch-ic-user-beginner:before {
  content: "\e29d";
}
.boschicon-bosch-ic-user-desktop:before {
  content: "\e29e";
}
.boschicon-bosch-ic-user-down:before {
  content: "\e29f";
}
.boschicon-bosch-ic-user-falling:before {
  content: "\e2a0";
}
.boschicon-bosch-ic-user-hand:before {
  content: "\e2a1";
}
.boschicon-bosch-ic-user-hand-stop:before {
  content: "\e2a2";
}
.boschicon-bosch-ic-user-professional:before {
  content: "\e2a3";
}
.boschicon-bosch-ic-user-run:before {
  content: "\e2a4";
}
.boschicon-bosch-ic-user-share:before {
  content: "\e2a5";
}
.boschicon-bosch-ic-user-voice:before {
  content: "\e2a6";
}
.boschicon-bosch-ic-user-walk:before {
  content: "\e2a7";
}
.boschicon-bosch-ic-video:before {
  content: "\e2a8";
}
.boschicon-bosch-ic-video-disabled-light:before {
  content: "\e2a9";
}
.boschicon-bosch-ic-video-record:before {
  content: "\e2aa";
}
.boschicon-bosch-ic-virtual-reality:before {
  content: "\e2ab";
}
.boschicon-bosch-ic-volume-disabled:before {
  content: "\e2ac";
}
.boschicon-bosch-ic-volume-high:before {
  content: "\e2ad";
}
.boschicon-bosch-ic-volume-low:before {
  content: "\e2ae";
}
.boschicon-bosch-ic-volume-off-light:before {
  content: "\e2af";
}
.boschicon-bosch-ic-wand:before {
  content: "\e2b0";
}
.boschicon-bosch-ic-warranty:before {
  content: "\e2b1";
}
.boschicon-bosch-ic-watch-off-disabled-light:before {
  content: "\e2b2";
}
.boschicon-bosch-ic-watch-on:before {
  content: "\e2b3";
}
.boschicon-bosch-ic-water-shower:before {
  content: "\e2b4";
}
.boschicon-bosch-ic-welcome:before {
  content: "\e2b5";
}
.boschicon-bosch-ic-wellness:before {
  content: "\e2b6";
}
.boschicon-bosch-ic-wheelchair:before {
  content: "\e2b7";
}
.boschicon-bosch-ic-whistle:before {
  content: "\e2b8";
}
.boschicon-bosch-ic-wide-angled-arrow:before {
  content: "\e2b9";
}
.boschicon-bosch-ic-wifi:before {
  content: "\e2ba";
}
.boschicon-bosch-ic-window-analysis:before {
  content: "\e2bb";
}
.boschicon-bosch-ic-window-browser:before {
  content: "\e2bc";
}
.boschicon-bosch-ic-window-console:before {
  content: "\e2bd";
}
.boschicon-bosch-ic-window-gaussian-view:before {
  content: "\e2be";
}
.boschicon-bosch-ic-window-info:before {
  content: "\e2bf";
}
.boschicon-bosch-ic-window-new:before {
  content: "\e2c0";
}
.boschicon-bosch-ic-window-resize:before {
  content: "\e2c1";
}
.boschicon-bosch-ic-window-scaling-view:before {
  content: "\e2c2";
}
.boschicon-bosch-ic-window-shuffle:before {
  content: "\e2c3";
}
.boschicon-bosch-ic-window-statistic:before {
  content: "\e2c4";
}
.boschicon-bosch-ic-wishlist:before {
  content: "\e2c5";
}
.boschicon-bosch-ic-work-order:before {
  content: "\e2c6";
}
.boschicon-bosch-ic-worldwideweb:before {
  content: "\e2c7";
}
.boschicon-bosch-ic-wrench:before {
  content: "\e2c8";
}
.boschicon-bosch-ic-zoom-in:before {
  content: "\e2c9";
}
.boschicon-bosch-ic-zoom-out:before {
  content: "\e2ca";
}
.boschicon-bosch-ic-scooter:before {
  content: "\e2cb";
}
.boschicon-bosch-ic-cars-traffic:before {
  content: "\e2cc";
}
.boschicon-bosch-ic-arm-chair:before {
  content: "\e2cd";
}
.boschicon-bosch-ic-car-phone:before {
  content: "\e2ce";
}
.boschicon-bosch-ic-box-lightbulb:before {
  content: "\e2cf";
}
.boschicon-bosch-ic-chair-officedesk:before {
  content: "\e2d0";
}
.boschicon-bosch-ic-calendar-clock:before {
  content: "\e2d1";
}
.boschicon-bosch-ic-document-cv:before {
  content: "\e2d2";
}
.boschicon-bosch-ic-user-run-bag:before {
  content: "\e2d3";
}
.boschicon-bosch-ic-brake-disk-spray:before {
  content: "\e2d4";
}
.boschicon-bosch-ic-circle-abs:before {
  content: "\e2d5";
}
.boschicon-bosch-ic-bright-down:before {
  content: "\e2d6";
}
.boschicon-bosch-ic-bright-up:before {
  content: "\e2d7";
}
.boschicon-bosch-ic-fan:before {
  content: "\e2d8";
}
.boschicon-bosch-ic-sun-moon:before {
  content: "\e2d9";
}
.boschicon-bosch-ic-lightbulb-attention:before {
  content: "\e2da";
}
.boschicon-bosch-ic-settings-attention:before {
  content: "\e2db";
}
.boschicon-bosch-ic-danger-hazards:before {
  content: "\e2dc";
}
.boschicon-bosch-ic-windshield-spray:before {
  content: "\e2dd";
}
.boschicon-bosch-ic-windshield-heat:before {
  content: "\e2de";
}
.boschicon-bosch-ic-light-drop:before {
  content: "\e2df";
}
.boschicon-bosch-ic-starter:before {
  content: "\e2e0";
}
.boschicon-bosch-ic-turbo:before {
  content: "\e2e1";
}
.boschicon-bosch-ic-settings-lock:before {
  content: "\e2e2";
}
.boschicon-bosch-ic-settings-n:before {
  content: "\e2e3";
}
.boschicon-bosch-ic-light-beam-high:before {
  content: "\e2e4";
}
.boschicon-bosch-ic-light-beam-low:before {
  content: "\e2e5";
}
.boschicon-bosch-ic-glas:before {
  content: "\e2e6";
}
.boschicon-bosch-ic-shirt-ice:before {
  content: "\e2e7";
}
.boschicon-bosch-ic-helmet-fan:before {
  content: "\e2e8";
}
.boschicon-bosch-ic-notification-arrow:before {
  content: "\e2e9";
}
.boschicon-bosch-ic-steering-wheel-arrow:before {
  content: "\e2ea";
}
.boschicon-bosch-ic-circle-arrow:before {
  content: "\e2eb";
}
.boschicon-bosch-ic-tempomat-pit:before {
  content: "\e2ec";
}
.boschicon-bosch-ic-radiator-fan-spray:before {
  content: "\e2ed";
}
.boschicon-bosch-ic-boost-down:before {
  content: "\e2ee";
}
.boschicon-bosch-ic-boost-up:before {
  content: "\e2ef";
}
.boschicon-bosch-ic-circle-lock:before {
  content: "\e2f0";
}
.boschicon-bosch-ic-mp-down:before {
  content: "\e2f1";
}
.boschicon-bosch-ic-mp-up:before {
  content: "\e2f2";
}
.boschicon-bosch-ic-tc-down:before {
  content: "\e2f3";
}
.boschicon-bosch-ic-tc-up:before {
  content: "\e2f4";
}
.boschicon-bosch-ic-danger-arrow-attention:before {
  content: "\e2f5";
}
.boschicon-bosch-ic-user-remove:before {
  content: "\e2f6";
}
.boschicon-bosch-ic-user-add:before {
  content: "\e2f7";
}
.boschicon-bosch-ic-post-it-collection:before {
  content: "\e2f8";
}
.boschicon-bosch-ic-horn:before {
  content: "\e2f9";
}
.boschicon-bosch-ic-desktop-notification:before {
  content: "\e2fa";
}
.boschicon-bosch-ic-router:before {
  content: "\e2fb";
}
.boschicon-bosch-ic-berry:before {
  content: "\e2fc";
}
.boschicon-bosch-ic-chat-language:before {
  content: "\e2fd";
}
.boschicon-bosch-ic-game-controller:before {
  content: "\e2fe";
}
.boschicon-bosch-ic-user-brain:before {
  content: "\e2ff";
}
.boschicon-bosch-ic-mouth-finger:before {
  content: "\e300";
}
.boschicon-bosch-ic-excavator-shovel:before {
  content: "\e301";
}
.boschicon-bosch-ic-ear-in:before {
  content: "\e302";
}
.boschicon-bosch-ic-ear-out:before {
  content: "\e303";
}
.boschicon-bosch-ic-lion:before {
  content: "\e304";
}
.boschicon-bosch-ic-car-convertible:before {
  content: "\e305";
}
.boschicon-bosch-ic-car-sports:before {
  content: "\e306";
}
.boschicon-bosch-ic-bear:before {
  content: "\e307";
}
.boschicon-bosch-ic-pot:before {
  content: "\e308";
}
.boschicon-bosch-ic-bed-double:before {
  content: "\e309";
}
.boschicon-bosch-ic-air-hot:before {
  content: "\e30a";
}
.boschicon-bosch-ic-air-ice:before {
  content: "\e30b";
}
.boschicon-bosch-ic-air-leaf:before {
  content: "\e30c";
}
.boschicon-bosch-ic-air-purifier-0:before {
  content: "\e30d";
}
.boschicon-bosch-ic-air-purifier-1:before {
  content: "\e30e";
}
.boschicon-bosch-ic-air-purifier-2:before {
  content: "\e30f";
}
.boschicon-bosch-ic-air-purifier-3:before {
  content: "\e310";
}
.boschicon-bosch-ic-air:before {
  content: "\e311";
}
.boschicon-bosch-ic-fan-off-disabled-bold:before {
  content: "\e312";
}
.boschicon-bosch-ic-ice-off-disabled-bold:before {
  content: "\e313";
}
.boschicon-bosch-ic-faucet-boost:before {
  content: "\e314";
}
.boschicon-bosch-ic-faucet-mode:before {
  content: "\e315";
}
.boschicon-bosch-ic-faucet-off-disabled-bold:before {
  content: "\e316";
}
.boschicon-bosch-ic-faucet-schedule:before {
  content: "\e317";
}
.boschicon-bosch-ic-fuel-cell:before {
  content: "\e318";
}
.boschicon-bosch-ic-heating-floor-off-disabled-bold:before {
  content: "\e319";
}
.boschicon-bosch-ic-heating-floor:before {
  content: "\e31a";
}
.boschicon-bosch-ic-heating-radiator-boost:before {
  content: "\e31b";
}
.boschicon-bosch-ic-heating-radiator-mode:before {
  content: "\e31c";
}
.boschicon-bosch-ic-heating-radiator-off-disabled-bold:before {
  content: "\e31d";
}
.boschicon-bosch-ic-heating-radiator-schedule:before {
  content: "\e31e";
}
.boschicon-bosch-ic-leaf-simple:before {
  content: "\e31f";
}
.boschicon-bosch-ic-oil-barrel:before {
  content: "\e320";
}
.boschicon-bosch-ic-water-bathtub:before {
  content: "\e321";
}
.boschicon-bosch-ic-water-dishwash:before {
  content: "\e322";
}
.boschicon-bosch-ic-water-handwash:before {
  content: "\e323";
}
.boschicon-bosch-ic-water-hot:before {
  content: "\e324";
}
.boschicon-bosch-ic-water-ice:before {
  content: "\e325";
}
.boschicon-bosch-ic-water-leaf:before {
  content: "\e326";
}
.boschicon-bosch-ic-water-ladder:before {
  content: "\e327";
}
.boschicon-bosch-ic-water-purifier-0:before {
  content: "\e328";
}
.boschicon-bosch-ic-water-purifier-1:before {
  content: "\e329";
}
.boschicon-bosch-ic-water-purifier-2:before {
  content: "\e32a";
}
.boschicon-bosch-ic-water-purifier-3:before {
  content: "\e32b";
}
.boschicon-bosch-ic-water-shower-boost:before {
  content: "\e32c";
}
.boschicon-bosch-ic-water-shower-mode:before {
  content: "\e32d";
}
.boschicon-bosch-ic-water-shower-off-disabled-bold:before {
  content: "\e32e";
}
.boschicon-bosch-ic-water-shower-schedule:before {
  content: "\e32f";
}
.boschicon-bosch-ic-water:before {
  content: "\e330";
}
.boschicon-bosch-ic-chart-bar-drop:before {
  content: "\e331";
}
.boschicon-bosch-ic-chart-bar-fire:before {
  content: "\e332";
}
.boschicon-bosch-ic-chart-bar-flash:before {
  content: "\e333";
}
.boschicon-bosch-ic-chart-bar-oil:before {
  content: "\e334";
}
.boschicon-bosch-ic-drop-minus:before {
  content: "\e335";
}
.boschicon-bosch-ic-drop-plus:before {
  content: "\e336";
}
.boschicon-bosch-ic-fan-minus:before {
  content: "\e337";
}
.boschicon-bosch-ic-fan-plus:before {
  content: "\e338";
}
.boschicon-bosch-ic-home-drops-0:before {
  content: "\e339";
}
.boschicon-bosch-ic-home-drops-1:before {
  content: "\e33a";
}
.boschicon-bosch-ic-home-drops-2:before {
  content: "\e33b";
}
.boschicon-bosch-ic-home-drops-3:before {
  content: "\e33c";
}
.boschicon-bosch-ic-home-temperature-in:before {
  content: "\e33d";
}
.boschicon-bosch-ic-home-temperature-out:before {
  content: "\e33e";
}
.boschicon-bosch-ic-home-user-in:before {
  content: "\e33f";
}
.boschicon-bosch-ic-ice-minus:before {
  content: "\e340";
}
.boschicon-bosch-ic-ice-plus:before {
  content: "\e341";
}
.boschicon-bosch-ic-chimney-sweeper:before {
  content: "\e342";
}
.boschicon-bosch-ic-menu-up:before {
  content: "\e343";
}
.boschicon-bosch-ic-security-ice:before {
  content: "\e344";
}
.boschicon-bosch-ic-security-legionella:before {
  content: "\e345";
}
.boschicon-bosch-ic-trolley-case:before {
  content: "\e346";
}
.boschicon-bosch-ic-sun-minus:before {
  content: "\e347";
}
.boschicon-bosch-ic-sun-plug:before {
  content: "\e348";
}
.boschicon-bosch-ic-sun-plus:before {
  content: "\e349";
}
.boschicon-bosch-ic-touch-pit:before {
  content: "\e34a";
}
.boschicon-bosch-ic-temperature-current:before {
  content: "\e34b";
}
.boschicon-bosch-ic-temperature-set:before {
  content: "\e34c";
}
.boschicon-bosch-ic-teddy-bear:before {
  content: "\e34d";
}
.boschicon-bosch-ic-document-svg:before {
  content: "\e34e";
}
.boschicon-bosch-ic-back-menu:before {
  content: "\e34f";
}
.boschicon-bosch-ic-car-arrows:before {
  content: "\e350";
}
.boschicon-bosch-ic-wrench-cloud:before {
  content: "\e351";
}
.boschicon-bosch-ic-weather-cloud-sun:before {
  content: "\e352";
}
.boschicon-bosch-ic-weather-cloudy:before {
  content: "\e353";
}
.boschicon-bosch-ic-weather-gale:before {
  content: "\e354";
}
.boschicon-bosch-ic-weather-mist:before {
  content: "\e355";
}
.boschicon-bosch-ic-weather-moon-cloud:before {
  content: "\e356";
}
.boschicon-bosch-ic-weather-rain-snow:before {
  content: "\e357";
}
.boschicon-bosch-ic-weather-rain-thunder:before {
  content: "\e358";
}
.boschicon-bosch-ic-weather-rain:before {
  content: "\e359";
}
.boschicon-bosch-ic-weather-snow:before {
  content: "\e35a";
}
.boschicon-bosch-ic-weather-thunder:before {
  content: "\e35b";
}
.boschicon-bosch-ic-weather-wind:before {
  content: "\e35c";
}
.boschicon-bosch-ic-plane-start:before {
  content: "\e35d";
}
.boschicon-bosch-ic-plane-land:before {
  content: "\e35e";
}
.boschicon-bosch-ic-graph-power:before {
  content: "\e35f";
}
.boschicon-bosch-ic-floorplan:before {
  content: "\e360";
}
.boschicon-bosch-ic-clock-pause:before {
  content: "\e361";
}
.boschicon-bosch-ic-clock-start:before {
  content: "\e362";
}
.boschicon-bosch-ic-clock-stop:before {
  content: "\e363";
}
.boschicon-bosch-ic-computer-retro:before {
  content: "\e364";
}
.boschicon-bosch-ic-smartphone-retro:before {
  content: "\e365";
}
.boschicon-bosch-ic-wood:before {
  content: "\e366";
}
.boschicon-bosch-ic-solid-fuel:before {
  content: "\e367";
}
.boschicon-bosch-ic-appliance-fan:before {
  content: "\e368";
}
.boschicon-bosch-ic-appliance-flame:before {
  content: "\e369";
}
.boschicon-bosch-ic-appliance-ice:before {
  content: "\e36a";
}
.boschicon-bosch-ic-appliance-oil:before {
  content: "\e36b";
}
.boschicon-bosch-ic-appliance-solid-fuel:before {
  content: "\e36c";
}
.boschicon-bosch-ic-appliance-sun-ice:before {
  content: "\e36d";
}
.boschicon-bosch-ic-appliance-sun:before {
  content: "\e36e";
}
.boschicon-bosch-ic-appliance-wood:before {
  content: "\e36f";
}
.boschicon-bosch-ic-appliance-air-leaf:before {
  content: "\e370";
}
.boschicon-bosch-ic-appliance-water-air:before {
  content: "\e371";
}
.boschicon-bosch-ic-appliance-water-leaf:before {
  content: "\e372";
}
.boschicon-bosch-ic-appliance-water-oil:before {
  content: "\e373";
}
.boschicon-bosch-ic-appliance-water:before {
  content: "\e374";
}
.boschicon-bosch-ic-appliance-heat-pump-air:before {
  content: "\e375";
}
.boschicon-bosch-ic-appliance-heat-pump-ground:before {
  content: "\e376";
}
.boschicon-bosch-ic-appliance-heat-pump-water:before {
  content: "\e377";
}
.boschicon-bosch-ic-appliance-plug-air:before {
  content: "\e378";
}
.boschicon-bosch-ic-appliance-plug-fuel-cell:before {
  content: "\e379";
}
.boschicon-bosch-ic-appliance-plug-leaf:before {
  content: "\e37a";
}
.boschicon-bosch-ic-appliance-plug-water-air:before {
  content: "\e37b";
}
.boschicon-bosch-ic-thermostat-connected-c:before {
  content: "\e37c";
}
.boschicon-bosch-ic-thermostat-connected-f:before {
  content: "\e37d";
}
.boschicon-bosch-ic-image-gallery:before {
  content: "\e37e";
}
.boschicon-bosch-ic-arrows-caution:before {
  content: "\e37f";
}
.boschicon-bosch-ic-arrows-diagonal-disabled-bold:before {
  content: "\e380";
}
.boschicon-bosch-ic-back-left-end:before {
  content: "\e381";
}
.boschicon-bosch-ic-back-right-end:before {
  content: "\e382";
}
.boschicon-bosch-ic-bar-chart-search:before {
  content: "\e383";
}
.boschicon-bosch-ic-batch:before {
  content: "\e384";
}
.boschicon-bosch-ic-calendar-remove:before {
  content: "\e385";
}
.boschicon-bosch-ic-call-bluetooth:before {
  content: "\e386";
}
.boschicon-bosch-ic-call-deny:before {
  content: "\e387";
}
.boschicon-bosch-ic-call-settings:before {
  content: "\e388";
}
.boschicon-bosch-ic-caution-sign-clock:before {
  content: "\e389";
}
.boschicon-bosch-ic-caution-sign-list:before {
  content: "\e38a";
}
.boschicon-bosch-ic-core-data:before {
  content: "\e38b";
}
.boschicon-bosch-ic-cursor-connected:before {
  content: "\e38c";
}
.boschicon-bosch-ic-cursor:before {
  content: "\e38d";
}
.boschicon-bosch-ic-dmc-code:before {
  content: "\e38e";
}
.boschicon-bosch-ic-find-id:before {
  content: "\e38f";
}
.boschicon-bosch-ic-globe-search:before {
  content: "\e390";
}
.boschicon-bosch-ic-interface:before {
  content: "\e391";
}
.boschicon-bosch-ic-material-add-manually:before {
  content: "\e392";
}
.boschicon-bosch-ic-material-list:before {
  content: "\e393";
}
.boschicon-bosch-ic-material-remove-manually:before {
  content: "\e394";
}
.boschicon-bosch-ic-material:before {
  content: "\e395";
}
.boschicon-bosch-ic-menu-checkmark:before {
  content: "\e396";
}
.boschicon-bosch-ic-qr-code:before {
  content: "\e397";
}
.boschicon-bosch-ic-sort-alphabetically:before {
  content: "\e398";
}
.boschicon-bosch-ic-unpin-classic-disabled-bold:before {
  content: "\e399";
}
.boschicon-bosch-ic-unpin-modern-disabled-bold:before {
  content: "\e39a";
}
.boschicon-bosch-ic-window-search:before {
  content: "\e39b";
}
.boschicon-bosch-ic-cloud-clock:before {
  content: "\e39c";
}
.boschicon-bosch-ic-concierge:before {
  content: "\e39d";
}
.boschicon-bosch-ic-heating-radiator:before {
  content: "\e39e";
}
.boschicon-bosch-ic-home-user-out:before {
  content: "\e39f";
}
.boschicon-bosch-ic-hot:before {
  content: "\e3a0";
}
.boschicon-bosch-ic-screen-service:before {
  content: "\e3a1";
}
.boschicon-bosch-ic-sun-ice:before {
  content: "\e3a2";
}
.boschicon-bosch-ic-sun-off-disabled-bold:before {
  content: "\e3a3";
}
.boschicon-bosch-ic-tractor:before {
  content: "\e3a4";
}
.boschicon-bosch-ic-video-recorder-digital:before {
  content: "\e3a5";
}
.boschicon-bosch-ic-windshield-wiper:before {
  content: "\e3a6";
}
.boschicon-bosch-ic-weather-snow-thunder:before {
  content: "\e3a7";
}
.boschicon-bosch-ic-electricity-pylon:before {
  content: "\e3a8";
}
.boschicon-bosch-ic-box-questionmark:before {
  content: "\e3a9";
}
.boschicon-bosch-ic-rainbow:before {
  content: "\e3aa";
}
.boschicon-bosch-ic-medal:before {
  content: "\e3ab";
}
.boschicon-bosch-ic-wheel:before {
  content: "\e3ac";
}
.boschicon-bosch-ic-borescope-connected:before {
  content: "\e3ad";
}
.boschicon-bosch-ic-filter-delete:before {
  content: "\e3ae";
}
.boschicon-bosch-ic-filter-add:before {
  content: "\e3af";
}
.boschicon-bosch-ic-man:before {
  content: "\e3b0";
}
.boschicon-bosch-ic-woman:before {
  content: "\e3b1";
}
.boschicon-bosch-ic-fridge:before {
  content: "\e3b2";
}
.boschicon-bosch-ic-battery-car:before {
  content: "\e3b3";
}
.boschicon-bosch-ic-bag-escalator:before {
  content: "\e3b4";
}
.boschicon-bosch-ic-shopping-bags:before {
  content: "\e3b5";
}
.boschicon-bosch-ic-car-steering-left:before {
  content: "\e3b6";
}
.boschicon-bosch-ic-car-steering-right:before {
  content: "\e3b7";
}
.boschicon-bosch-ic-thumb-up-down:before {
  content: "\e3b8";
}
.boschicon-bosch-ic-user-run-detection:before {
  content: "\e3b9";
}
.boschicon-bosch-ic-bank:before {
  content: "\e3ba";
}
.boschicon-bosch-ic-transportation:before {
  content: "\e3bb";
}
.boschicon-bosch-ic-government:before {
  content: "\e3bc";
}
.boschicon-bosch-ic-user-music:before {
  content: "\e3bd";
}
.boschicon-bosch-ic-bus:before {
  content: "\e3be";
}
.boschicon-bosch-ic-car-euro:before {
  content: "\e3bf";
}
.boschicon-bosch-ic-bag:before {
  content: "\e3c0";
}
.boschicon-bosch-ic-plate-tetrapack:before {
  content: "\e3c1";
}
.boschicon-bosch-ic-hospital:before {
  content: "\e3c2";
}
.boschicon-bosch-ic-desktop-structure:before {
  content: "\e3c3";
}
.boschicon-bosch-ic-frog-head:before {
  content: "\e3c4";
}
.boschicon-bosch-ic-sportboat:before {
  content: "\e3c5";
}
.boschicon-bosch-ic-yacht:before {
  content: "\e3c6";
}
.boschicon-bosch-ic-leaf-tilia:before {
  content: "\e3c7";
}
.boschicon-bosch-ic-music:before {
  content: "\e3c8";
}
.boschicon-bosch-ic-oil-lamp:before {
  content: "\e3c9";
}
.boschicon-bosch-ic-windshield-wiper-rear:before {
  content: "\e3ca";
}
.boschicon-bosch-ic-dongle-connected:before {
  content: "\e3cb";
}
.boschicon-bosch-ic-slate:before {
  content: "\e3cc";
}
.boschicon-bosch-ic-appliance-heat-pump-exhaust:before {
  content: "\e3cd";
}
.boschicon-bosch-ic-alert-list:before {
  content: "\e3ce";
}
.boschicon-bosch-ic-arrow-left-end-frame:before {
  content: "\e3cf";
}
.boschicon-bosch-ic-arrow-right-end-frame:before {
  content: "\e3d0";
}
.boschicon-bosch-ic-shape-ellipse:before {
  content: "\e3d1";
}
.boschicon-bosch-ic-shape-rectangle:before {
  content: "\e3d2";
}
.boschicon-bosch-ic-wishlist-add:before {
  content: "\e3d3";
}
.boschicon-bosch-ic-smartphone-disabled-light:before {
  content: "\e3d4";
}
.boschicon-bosch-ic-airbag:before {
  content: "\e3d5";
}
.boschicon-bosch-ic-compass:before {
  content: "\e3d6";
}
.boschicon-bosch-ic-turbine-wind:before {
  content: "\e3d7";
}
.boschicon-bosch-ic-arrows-diagonal-disabled-light:before {
  content: "\e3d8";
}
.boschicon-bosch-ic-ear-off-disabled-bold:before {
  content: "\e3d9";
}
.boschicon-bosch-ic-fan-off-disabled-light:before {
  content: "\e3da";
}
.boschicon-bosch-ic-faucet-off-disabled-light:before {
  content: "\e3db";
}
.boschicon-bosch-ic-hand-motion-off-disabled-bold:before {
  content: "\e3dc";
}
.boschicon-bosch-ic-heating-floor-off-disabled-light:before {
  content: "\e3dd";
}
.boschicon-bosch-ic-heating-radiator-off-disabled-light:before {
  content: "\e3de";
}
.boschicon-bosch-ic-ice-off-disabled-light:before {
  content: "\e3df";
}
.boschicon-bosch-ic-smartphone-disabled-bold:before {
  content: "\e3e0";
}
.boschicon-bosch-ic-subtitles-off-bold:before {
  content: "\e3e1";
}
.boschicon-bosch-ic-sun-off-disabled-light:before {
  content: "\e3e2";
}
.boschicon-bosch-ic-video-disabled-bold:before {
  content: "\e3e3";
}
.boschicon-bosch-ic-volume-off-bold:before {
  content: "\e3e4";
}
.boschicon-bosch-ic-watch-off-disabled-bold:before {
  content: "\e3e5";
}
.boschicon-bosch-ic-water-shower-off-disabled-light:before {
  content: "\e3e6";
}
.boschicon-bosch-ic-3d-frame-play:before {
  content: "\e3e7";
}
.boschicon-bosch-ic-alert-error-checkmark:before {
  content: "\e3e8";
}
.boschicon-bosch-ic-arrow-up-frame-check:before {
  content: "\e3e9";
}
.boschicon-bosch-ic-arrow-up-frame-error:before {
  content: "\e3ea";
}
.boschicon-bosch-ic-arrows-checkmark:before {
  content: "\e3eb";
}
.boschicon-bosch-ic-assembly-line-settings:before {
  content: "\e3ec";
}
.boschicon-bosch-ic-augmented-reality-player:before {
  content: "\e3ed";
}
.boschicon-bosch-ic-badge-checkmark:before {
  content: "\e3ee";
}
.boschicon-bosch-ic-bar-chart-kappa:before {
  content: "\e3ef";
}
.boschicon-bosch-ic-bar-chart-search-detail:before {
  content: "\e3f0";
}
.boschicon-bosch-ic-barcode-checkmark:before {
  content: "\e3f1";
}
.boschicon-bosch-ic-barcode-scan-bulb:before {
  content: "\e3f2";
}
.boschicon-bosch-ic-barcode:before {
  content: "\e3f3";
}
.boschicon-bosch-ic-binary:before {
  content: "\e3f4";
}
.boschicon-bosch-ic-book-lightbulb:before {
  content: "\e3f5";
}
.boschicon-bosch-ic-box-arrow-down:before {
  content: "\e3f6";
}
.boschicon-bosch-ic-box-delivery-checkmark:before {
  content: "\e3f7";
}
.boschicon-bosch-ic-globe-checkmark:before {
  content: "\e3f8";
}
.boschicon-bosch-ic-calendar-boxes:before {
  content: "\e3f9";
}
.boschicon-bosch-ic-calendar-service:before {
  content: "\e3fa";
}
.boschicon-bosch-ic-camera-processor:before {
  content: "\e3fb";
}
.boschicon-bosch-ic-camera-settings:before {
  content: "\e3fc";
}
.boschicon-bosch-ic-chart-line-checkmark:before {
  content: "\e3fd";
}
.boschicon-bosch-ic-checklist-stack:before {
  content: "\e3fe";
}
.boschicon-bosch-ic-checkmark-frame-minimum:before {
  content: "\e3ff";
}
.boschicon-bosch-ic-checkmark-frame-tripple:before {
  content: "\e400";
}
.boschicon-bosch-ic-clipboard-checkmark:before {
  content: "\e401";
}
.boschicon-bosch-ic-clipboard-list-parts:before {
  content: "\e402";
}
.boschicon-bosch-ic-clipboard-settings:before {
  content: "\e403";
}
.boschicon-bosch-ic-computer-tower-box:before {
  content: "\e404";
}
.boschicon-bosch-ic-connectivity-lock:before {
  content: "\e405";
}
.boschicon-bosch-ic-counter-current:before {
  content: "\e406";
}
.boschicon-bosch-ic-counter-target:before {
  content: "\e407";
}
.boschicon-bosch-ic-cube-checkmark:before {
  content: "\e408";
}
.boschicon-bosch-ic-cube-play:before {
  content: "\e409";
}
.boschicon-bosch-ic-timeline-settings:before {
  content: "\e40a";
}
.boschicon-bosch-ic-timeline-view:before {
  content: "\e40b";
}
.boschicon-bosch-ic-timeline:before {
  content: "\e40c";
}
.boschicon-bosch-ic-delivery-checkmark:before {
  content: "\e40d";
}
.boschicon-bosch-ic-desktop-application-3d-play:before {
  content: "\e40e";
}
.boschicon-bosch-ic-desktop-apps-download:before {
  content: "\e40f";
}
.boschicon-bosch-ic-desktop-dashboard:before {
  content: "\e410";
}
.boschicon-bosch-ic-desktop-graph-checkmark:before {
  content: "\e411";
}
.boschicon-bosch-ic-desktop-graph-search:before {
  content: "\e412";
}
.boschicon-bosch-ic-desktop-graph:before {
  content: "\e413";
}
.boschicon-bosch-ic-development-kit:before {
  content: "\e414";
}
.boschicon-bosch-ic-directory-cloud-settings:before {
  content: "\e415";
}
.boschicon-bosch-ic-directory-cloud:before {
  content: "\e416";
}
.boschicon-bosch-ic-directory-settings:before {
  content: "\e417";
}
.boschicon-bosch-ic-document-clock-cycle:before {
  content: "\e418";
}
.boschicon-bosch-ic-document-code-stack:before {
  content: "\e419";
}
.boschicon-bosch-ic-document-dat-edit:before {
  content: "\e41a";
}
.boschicon-bosch-ic-document-ohd-arrow-down:before {
  content: "\e41b";
}
.boschicon-bosch-ic-dolphin:before {
  content: "\e41c";
}
.boschicon-bosch-ic-energy-management:before {
  content: "\e41d";
}
.boschicon-bosch-ic-eraser:before {
  content: "\e41e";
}
.boschicon-bosch-ic-folder-broom:before {
  content: "\e41f";
}
.boschicon-bosch-ic-folder-check-broom:before {
  content: "\e420";
}
.boschicon-bosch-ic-folder-checkmark:before {
  content: "\e421";
}
.boschicon-bosch-ic-folder-oes-check:before {
  content: "\e422";
}
.boschicon-bosch-ic-folder-open-service:before {
  content: "\e423";
}
.boschicon-bosch-ic-folder-reload:before {
  content: "\e424";
}
.boschicon-bosch-ic-hand-command:before {
  content: "\e425";
}
.boschicon-bosch-ic-heating-start:before {
  content: "\e426";
}
.boschicon-bosch-ic-hierarchy-refresh:before {
  content: "\e427";
}
.boschicon-bosch-ic-hierarchy-search:before {
  content: "\e428";
}
.boschicon-bosch-ic-hierarchy-settings:before {
  content: "\e429";
}
.boschicon-bosch-ic-hierarchy:before {
  content: "\e42a";
}
.boschicon-bosch-ic-hmi-desktop-settings:before {
  content: "\e42b";
}
.boschicon-bosch-ic-human-machine-interface:before {
  content: "\e42c";
}
.boschicon-bosch-ic-keys-user-access:before {
  content: "\e42d";
}
.boschicon-bosch-ic-keys:before {
  content: "\e42e";
}
.boschicon-bosch-ic-label-edit:before {
  content: "\e42f";
}
.boschicon-bosch-ic-line-arrow-checkmark:before {
  content: "\e430";
}
.boschicon-bosch-ic-link-checkmark:before {
  content: "\e431";
}
.boschicon-bosch-ic-lock-closed-checkmark:before {
  content: "\e432";
}
.boschicon-bosch-ic-lock-open-checkmark:before {
  content: "\e433";
}
.boschicon-bosch-ic-material-search:before {
  content: "\e434";
}
.boschicon-bosch-ic-objects-search:before {
  content: "\e435";
}
.boschicon-bosch-ic-objects:before {
  content: "\e436";
}
.boschicon-bosch-ic-plan-a-to-b:before {
  content: "\e437";
}
.boschicon-bosch-ic-plan-chart:before {
  content: "\e438";
}
.boschicon-bosch-ic-print-send:before {
  content: "\e439";
}
.boschicon-bosch-ic-refresh-analysis:before {
  content: "\e43a";
}
.boschicon-bosch-ic-remote-desktop-add:before {
  content: "\e43b";
}
.boschicon-bosch-ic-rfid-checkmark:before {
  content: "\e43c";
}
.boschicon-bosch-ic-rfid-tag:before {
  content: "\e43d";
}
.boschicon-bosch-ic-sequence-checkmark:before {
  content: "\e43e";
}
.boschicon-bosch-ic-server-arrow-up:before {
  content: "\e43f";
}
.boschicon-bosch-ic-server-arrows:before {
  content: "\e440";
}
.boschicon-bosch-ic-server-settings:before {
  content: "\e441";
}
.boschicon-bosch-ic-server-share:before {
  content: "\e442";
}
.boschicon-bosch-ic-settings-editor:before {
  content: "\e443";
}
.boschicon-bosch-ic-survey-checkmark:before {
  content: "\e444";
}
.boschicon-bosch-ic-target-dart:before {
  content: "\e445";
}
.boschicon-bosch-ic-target-view:before {
  content: "\e446";
}
.boschicon-bosch-ic-target:before {
  content: "\e447";
}
.boschicon-bosch-ic-thickness-selection:before {
  content: "\e448";
}
.boschicon-bosch-ic-toolbox-checkmark:before {
  content: "\e449";
}
.boschicon-bosch-ic-toolbox:before {
  content: "\e44a";
}
.boschicon-bosch-ic-translate:before {
  content: "\e44b";
}
.boschicon-bosch-ic-tune-vertical-checkmark:before {
  content: "\e44c";
}
.boschicon-bosch-ic-wand-user-add:before {
  content: "\e44d";
}
.boschicon-bosch-ic-wand-user-settings:before {
  content: "\e44e";
}
.boschicon-bosch-ic-wand-user:before {
  content: "\e44f";
}
.boschicon-bosch-ic-window-globe:before {
  content: "\e450";
}
.boschicon-bosch-ic-wrench-change:before {
  content: "\e451";
}
.boschicon-bosch-ic-clock-24-7:before {
  content: "\e452";
}
.boschicon-bosch-ic-eagle:before {
  content: "\e453";
}
.boschicon-bosch-ic-fluorescent-light:before {
  content: "\e454";
}
.boschicon-bosch-ic-home-cloud-0:before {
  content: "\e455";
}
.boschicon-bosch-ic-home-cloud-1:before {
  content: "\e456";
}
.boschicon-bosch-ic-home-cloud-2:before {
  content: "\e457";
}
.boschicon-bosch-ic-home-cloud-3:before {
  content: "\e458";
}
.boschicon-bosch-ic-home-shadow:before {
  content: "\e459";
}
.boschicon-bosch-ic-motorcycle-sport:before {
  content: "\e45a";
}
.boschicon-bosch-ic-smartphone-connected:before {
  content: "\e45b";
}
.boschicon-bosch-ic-usb-stick:before {
  content: "\e45c";
}
.boschicon-bosch-ic-user--setting:before {
  content: "\e45d";
}
.boschicon-bosch-ic-timeline-clock:before {
  content: "\e45e";
}
.boschicon-bosch-ic-alarm-fire:before {
  content: "\e45f";
}
.boschicon-bosch-ic-emergency-front:before {
  content: "\e460";
}
.boschicon-bosch-ic-transporter-front:before {
  content: "\e461";
}
.boschicon-bosch-ic-truck-front:before {
  content: "\e462";
}
.boschicon-bosch-ic-unpin-classic-disabled-light:before {
  content: "\e463";
}
.boschicon-bosch-ic-unpin-modern-disabled-light:before {
  content: "\e464";
}
.boschicon-bosch-ic-smartphone-ban:before {
  content: "\e465";
}
.boschicon-bosch-ic-helmet:before {
  content: "\e466";
}
.boschicon-bosch-ic-gate-closed:before {
  content: "\e467";
}
.boschicon-bosch-ic-fence:before {
  content: "\e468";
}
.boschicon-bosch-ic-desktop-apps:before {
  content: "\e469";
}
.boschicon-bosch-ic-candle:before {
  content: "\e46a";
}
.boschicon-bosch-ic-chart-oee:before {
  content: "\e46b";
}
.boschicon-bosch-ic-cycletime-eighth:before {
  content: "\e46c";
}
.boschicon-bosch-ic-cycletime-quarter:before {
  content: "\e46d";
}
.boschicon-bosch-ic-gateway:before {
  content: "\e46e";
}
.boschicon-bosch-ic-heatmap:before {
  content: "\e46f";
}
.boschicon-bosch-ic-laptop-checklist:before {
  content: "\e470";
}
.boschicon-bosch-ic-molecule:before {
  content: "\e471";
}
.boschicon-bosch-ic-robot-settings:before {
  content: "\e472";
}
.boschicon-bosch-ic-sensor-cable:before {
  content: "\e473";
}
.boschicon-bosch-ic-tank-layers:before {
  content: "\e474";
}
.boschicon-bosch-ic-window-settings:before {
  content: "\e475";
}
.boschicon-bosch-ic-heatmap-independent:before {
  content: "\e476";
}
.boschicon-bosch-ic-dumble:before {
  content: "\e477";
}
.boschicon-bosch-ic-truck-wash:before {
  content: "\e478";
}
.boschicon-bosch-ic-smart-sensor:before {
  content: "\e479";
}
.boschicon-bosch-ic-audio-add:before {
  content: "\e47a";
}
.boschicon-bosch-ic-audio-checked:before {
  content: "\e47b";
}
.boschicon-bosch-ic-audio-play:before {
  content: "\e47c";
}
.boschicon-bosch-ic-audio-remove:before {
  content: "\e47d";
}
.boschicon-bosch-ic-image-gallery-checked:before {
  content: "\e47e";
}
.boschicon-bosch-ic-imagery-checked:before {
  content: "\e47f";
}
.boschicon-bosch-ic-image-gallery-remove:before {
  content: "\e480";
}
.boschicon-bosch-ic-recording:before {
  content: "\e481";
}
.boschicon-bosch-ic-stop-frame:before {
  content: "\e482";
}
.boschicon-bosch-ic-roof:before {
  content: "\e483";
}
.boschicon-bosch-ic-projection-interactive:before {
  content: "\e484";
}
.boschicon-bosch-ic-bridge:before {
  content: "\e485";
}
.boschicon-bosch-ic-box-arrows:before {
  content: "\e486";
}
.boschicon-bosch-ic-chart-line-fit:before {
  content: "\e487";
}
.boschicon-bosch-ic-chart-line-compress:before {
  content: "\e488";
}
.boschicon-bosch-ic-ciss:before {
  content: "\e489";
}
.boschicon-bosch-ic-scd:before {
  content: "\e48a";
}
.boschicon-bosch-ic-vci:before {
  content: "\e48b";
}
.boschicon-bosch-ic-access-controller:before {
  content: "\e48c";
}
.boschicon-bosch-ic-card-reader:before {
  content: "\e48d";
}
.boschicon-bosch-ic-table:before {
  content: "\e48e";
}
.boschicon-bosch-ic-replace:before {
  content: "\e48f";
}
.boschicon-bosch-ic-chart-topflop-organized-horizontal:before {
  content: "\e490";
}
.boschicon-bosch-ic-chart-topflop-random-horizontal:before {
  content: "\e491";
}
.boschicon-bosch-ic-chart-topflop-organized-vertical:before {
  content: "\e492";
}
.boschicon-bosch-ic-chart-topflop-random-vertical:before {
  content: "\e493";
}
.boschicon-bosch-ic-ois:before {
  content: "\e494";
}
.boschicon-bosch-ic-cloud-refresh-locked:before {
  content: "\e495";
}
.boschicon-bosch-ic-car-bluetooth:before {
  content: "\e496";
}
.boschicon-bosch-ic-camera-trainer:before {
  content: "\e497";
}
.boschicon-bosch-ic-magnifier-moving:before {
  content: "\e498";
}
.boschicon-bosch-ic-absolute-orientation:before {
  content: "\e499";
}
.boschicon-bosch-ic-accelerometer:before {
  content: "\e49a";
}
.boschicon-bosch-ic-imu:before {
  content: "\e49b";
}
.boschicon-bosch-ic-gyroscope:before {
  content: "\e49c";
}
.boschicon-bosch-ic-magneotmeter:before {
  content: "\e49d";
}
.boschicon-bosch-ic-battery-car-1:before {
  content: "\e49e";
}
.boschicon-bosch-ic-battery-car-2:before {
  content: "\e49f";
}
.boschicon-bosch-ic-battery-car-3:before {
  content: "\e4a0";
}
.boschicon-bosch-ic-battery-car-charging:before {
  content: "\e4a1";
}
.boschicon-bosch-ic-battery-car-defective:before {
  content: "\e4a2";
}
.boschicon-bosch-ic-truck-crane:before {
  content: "\e4a3";
}
.boschicon-bosch-ic-train-diesel:before {
  content: "\e4a4";
}
.boschicon-bosch-ic-snowmobile:before {
  content: "\e4a5";
}
.boschicon-bosch-ic-riding-mower:before {
  content: "\e4a6";
}
.boschicon-bosch-ic-motorcycle-small:before {
  content: "\e4a7";
}
.boschicon-bosch-ic-mining-truck:before {
  content: "\e4a8";
}
.boschicon-bosch-ic-lawnmower:before {
  content: "\e4a9";
}
.boschicon-bosch-ic-jetski:before {
  content: "\e4aa";
}
.boschicon-bosch-ic-golf-caddy:before {
  content: "\e4ab";
}
.boschicon-bosch-ic-generator-electric:before {
  content: "\e4ac";
}
.boschicon-bosch-ic-garbage-truck:before {
  content: "\e4ad";
}
.boschicon-bosch-ic-fork-lift-big:before {
  content: "\e4ae";
}
.boschicon-bosch-ic-engine-small:before {
  content: "\e4af";
}
.boschicon-bosch-ic-engine-big:before {
  content: "\e4b0";
}
.boschicon-bosch-ic-coach:before {
  content: "\e4b1";
}
.boschicon-bosch-ic-chainsaw:before {
  content: "\e4b2";
}
.boschicon-bosch-ic-car-van:before {
  content: "\e4b3";
}
.boschicon-bosch-ic-car-suv:before {
  content: "\e4b4";
}
.boschicon-bosch-ic-car-race:before {
  content: "\e4b5";
}
.boschicon-bosch-ic-car-old:before {
  content: "\e4b6";
}
.boschicon-bosch-ic-car-camping-van:before {
  content: "\e4b7";
}
.boschicon-bosch-ic-car-camper:before {
  content: "\e4b8";
}
.boschicon-bosch-ic-bus-side:before {
  content: "\e4b9";
}
.boschicon-bosch-ic-book-info:before {
  content: "\e4ba";
}
.boschicon-bosch-ic-gears:before {
  content: "\e4bb";
}
.boschicon-bosch-ic-shark:before {
  content: "\e4bc";
}
.boschicon-bosch-ic-mountaintop:before {
  content: "\e4bd";
}
.boschicon-bosch-ic-baby:before {
  content: "\e4be";
}
.boschicon-bosch-ic-coffee-bean:before {
  content: "\e4bf";
}
.boschicon-bosch-ic-coffee-beans:before {
  content: "\e4c0";
}
.boschicon-bosch-ic-skull:before {
  content: "\e4c1";
}
.boschicon-bosch-ic-stroller:before {
  content: "\e4c2";
}
.boschicon-bosch-ic-glasses:before {
  content: "\e4c3";
}
.boschicon-bosch-ic-detergent:before {
  content: "\e4c4";
}
.boschicon-bosch-ic-washing-machine:before {
  content: "\e4c5";
}
.boschicon-bosch-ic-sdcard:before {
  content: "\e4c6";
}
.boschicon-bosch-ic-water-connectivity:before {
  content: "\e4c7";
}
.boschicon-bosch-ic-server-connectivity:before {
  content: "\e4c8";
}
.boschicon-bosch-ic-presentation-speaker:before {
  content: "\e4c9";
}
.boschicon-bosch-ic-chart-bar-arrow:before {
  content: "\e4ca";
}
.boschicon-bosch-ic-book-connectivity:before {
  content: "\e4cb";
}
.boschicon-bosch-ic-route-focus:before {
  content: "\e4cc";
}
.boschicon-bosch-ic-bicycle-helmet:before {
  content: "\e4cd";
}
.boschicon-bosch-ic-cookie:before {
  content: "\e4ce";
}
.boschicon-bosch-ic-glass-wine:before {
  content: "\e4cf";
}
.boschicon-bosch-ic-cup-small:before {
  content: "\e4d0";
}
.boschicon-bosch-ic-cup-large:before {
  content: "\e4d1";
}
.boschicon-bosch-ic-bowl:before {
  content: "\e4d2";
}
.boschicon-bosch-ic-glass-small:before {
  content: "\e4d3";
}
.boschicon-bosch-ic-ice-bottle:before {
  content: "\e4d4";
}
.boschicon-bosch-ic-cup-plate-drop:before {
  content: "\e4d5";
}
.boschicon-bosch-ic-plate:before {
  content: "\e4d6";
}
.boschicon-bosch-ic-cup-plate:before {
  content: "\e4d7";
}
.boschicon-bosch-ic-bubbles:before {
  content: "\e4d8";
}
.boschicon-bosch-ic-button-start-stop:before {
  content: "\e4d9";
}
.boschicon-bosch-ic-elevator-freight-max:before {
  content: "\e4da";
}
.boschicon-bosch-ic-elevator-freight:before {
  content: "\e4db";
}
.boschicon-bosch-ic-elevator-persons:before {
  content: "\e4dc";
}
.boschicon-bosch-ic-embed:before {
  content: "\e4dd";
}
.boschicon-bosch-ic-female-male:before {
  content: "\e4de";
}
.boschicon-bosch-ic-fork-knife-spoon:before {
  content: "\e4df";
}
.boschicon-bosch-ic-global-entrepreneur:before {
  content: "\e4e0";
}
.boschicon-bosch-ic-arrows-left-right-checkmark:before {
  content: "\e4e1";
}
.boschicon-bosch-ic-automatic-mode:before {
  content: "\e4e2";
}
.boschicon-bosch-ic-babybottle:before {
  content: "\e4e3";
}
.boschicon-bosch-ic-barcode-scan:before {
  content: "\e4e4";
}
.boschicon-bosch-ic-barcode-scanner:before {
  content: "\e4e5";
}
.boschicon-bosch-ic-cash-frame-rupee:before {
  content: "\e4e6";
}
.boschicon-bosch-ic-chart-heat-curve:before {
  content: "\e4e7";
}
.boschicon-bosch-ic-compressor:before {
  content: "\e4e8";
}
.boschicon-bosch-ic-cubes:before {
  content: "\e4e9";
}
.boschicon-bosch-ic-highway:before {
  content: "\e4ea";
}
.boschicon-bosch-ic-industry-workers:before {
  content: "\e4eb";
}
.boschicon-bosch-ic-industry-workers-connected:before {
  content: "\e4ec";
}
.boschicon-bosch-ic-machine-station-control:before {
  content: "\e4ed";
}
.boschicon-bosch-ic-map-city:before {
  content: "\e4ee";
}
.boschicon-bosch-ic-ordernumber:before {
  content: "\e4ef";
}
.boschicon-bosch-ic-shirt-business:before {
  content: "\e4f0";
}
.boschicon-bosch-ic-shirt-casual:before {
  content: "\e4f1";
}
.boschicon-bosch-ic-suitcase:before {
  content: "\e4f2";
}
.boschicon-bosch-ic-weather-cloud-sun-sensor:before {
  content: "\e4f3";
}
.boschicon-bosch-ic-window-code:before {
  content: "\e4f4";
}
.boschicon-bosch-ic-wrench-warning:before {
  content: "\e4f5";
}
.boschicon-bosch-ic-arrow-circle-a:before {
  content: "\e4f6";
}
.boschicon-bosch-ic-arrows-agility:before {
  content: "\e4f7";
}
.boschicon-bosch-ic-arrows-collapse-object:before {
  content: "\e4f8";
}
.boschicon-bosch-ic-arrows-expand-object:before {
  content: "\e4f9";
}
.boschicon-bosch-ic-arrows-left-right:before {
  content: "\e4fa";
}
.boschicon-bosch-ic-fan-heat:before {
  content: "\e4fb";
}
.boschicon-bosch-ic-battery-car-arrows-circle:before {
  content: "\e4fc";
}
.boschicon-bosch-ic-battery-car-clock:before {
  content: "\e4fd";
}
.boschicon-bosch-ic-battery-vibration:before {
  content: "\e4fe";
}
.boschicon-bosch-ic-belt-elastic:before {
  content: "\e4ff";
}
.boschicon-bosch-ic-bicycle-e-arrow-away:before {
  content: "\e500";
}
.boschicon-bosch-ic-bicycle-e-arrow-towards:before {
  content: "\e501";
}
.boschicon-bosch-ic-brake-decoupled-power:before {
  content: "\e502";
}
.boschicon-bosch-ic-brake-i-booster:before {
  content: "\e503";
}
.boschicon-bosch-ic-brake-system-esp:before {
  content: "\e504";
}
.boschicon-bosch-ic-brake-system-integrated:before {
  content: "\e505";
}
.boschicon-bosch-ic-brake-vacuum-booster:before {
  content: "\e506";
}
.boschicon-bosch-ic-capacity:before {
  content: "\e507";
}
.boschicon-bosch-ic-car-beam-length:before {
  content: "\e508";
}
.boschicon-bosch-ic-car-smartphone-locator:before {
  content: "\e509";
}
.boschicon-bosch-ic-chart-pie:before {
  content: "\e50a";
}
.boschicon-bosch-ic-chess-jumper:before {
  content: "\e50b";
}
.boschicon-bosch-ic-clock-time-off:before {
  content: "\e50c";
}
.boschicon-bosch-ic-clock-time-on:before {
  content: "\e50d";
}
.boschicon-bosch-ic-coin:before {
  content: "\e50e";
}
.boschicon-bosch-ic-connection-off-settings:before {
  content: "\e50f";
}
.boschicon-bosch-ic-connection-on-settings:before {
  content: "\e510";
}
.boschicon-bosch-ic-control-unit:before {
  content: "\e511";
}
.boschicon-bosch-ic-customer:before {
  content: "\e512";
}
.boschicon-bosch-ic-engine:before {
  content: "\e513";
}
.boschicon-bosch-ic-filter-air:before {
  content: "\e514";
}
.boschicon-bosch-ic-filter-cabin-carbon:before {
  content: "\e515";
}
.boschicon-bosch-ic-filter-cabin:before {
  content: "\e516";
}
.boschicon-bosch-ic-filter-cooling-water:before {
  content: "\e517";
}
.boschicon-bosch-ic-filter-denox-cover:before {
  content: "\e518";
}
.boschicon-bosch-ic-filter-denox:before {
  content: "\e519";
}
.boschicon-bosch-ic-filter-element-compensation-sealing:before {
  content: "\e51a";
}
.boschicon-bosch-ic-filter-element-compensation:before {
  content: "\e51b";
}
.boschicon-bosch-ic-filter-element:before {
  content: "\e51c";
}
.boschicon-bosch-ic-filter-oil:before {
  content: "\e51d";
}
.boschicon-bosch-ic-filter-petrol:before {
  content: "\e51e";
}
.boschicon-bosch-ic-filter-prefilter:before {
  content: "\e51f";
}
.boschicon-bosch-ic-fireplace:before {
  content: "\e520";
}
.boschicon-bosch-ic-flash-plus:before {
  content: "\e521";
}
.boschicon-bosch-ic-globe-worldwideweb:before {
  content: "\e522";
}
.boschicon-bosch-ic-glow-control-unit:before {
  content: "\e523";
}
.boschicon-bosch-ic-glow-plug:before {
  content: "\e524";
}
.boschicon-bosch-ic-hand-cloth:before {
  content: "\e525";
}
.boschicon-bosch-ic-leaf-plus:before {
  content: "\e526";
}
.boschicon-bosch-ic-machine-warning:before {
  content: "\e527";
}
.boschicon-bosch-ic-measuring-jug:before {
  content: "\e528";
}
.boschicon-bosch-ic-plug-play:before {
  content: "\e529";
}
.boschicon-bosch-ic-refresh-checkmark:before {
  content: "\e52a";
}
.boschicon-bosch-ic-replacement-filament:before {
  content: "\e52b";
}
.boschicon-bosch-ic-screw:before {
  content: "\e52c";
}
.boschicon-bosch-ic-seat-belt:before {
  content: "\e52d";
}
.boschicon-bosch-ic-steering-wheel-hands-automated:before {
  content: "\e52e";
}
.boschicon-bosch-ic-sun-ice-parking:before {
  content: "\e52f";
}
.boschicon-bosch-ic-syringe-change-needle:before {
  content: "\e530";
}
.boschicon-bosch-ic-syringe-change:before {
  content: "\e531";
}
.boschicon-bosch-ic-syringe:before {
  content: "\e532";
}
.boschicon-bosch-ic-thread-diameter:before {
  content: "\e533";
}
.boschicon-bosch-ic-tool-extraction:before {
  content: "\e534";
}
.boschicon-bosch-ic-transporter-car-front:before {
  content: "\e535";
}
.boschicon-bosch-ic-truck-car-motorcycle:before {
  content: "\e536";
}
.boschicon-bosch-ic-truck-car:before {
  content: "\e537";
}
.boschicon-bosch-ic-truck-logistics-box:before {
  content: "\e538";
}
.boschicon-bosch-ic-truck-transporter:before {
  content: "\e539";
}
.boschicon-bosch-ic-watch-on-warning:before {
  content: "\e53a";
}
.boschicon-bosch-ic-wrench-calibrate:before {
  content: "\e53b";
}
.boschicon-bosch-ic-wrench-dummy:before {
  content: "\e53c";
}
.boschicon-bosch-ic-wrench-tightening-torque:before {
  content: "\e53d";
}
.boschicon-bosch-ic-wrench-width-across-flats:before {
  content: "\e53e";
}
.boschicon-bosch-ic-user-arm-bandage:before {
  content: "\e53f";
}
.boschicon-bosch-ic-user-eye-blind:before {
  content: "\e540";
}
.boschicon-bosch-ic-user-ear-deaf:before {
  content: "\e541";
}
.boschicon-bosch-ic-user-car-distracted:before {
  content: "\e542";
}
.boschicon-bosch-ic-user-mouth-mute:before {
  content: "\e543";
}
.boschicon-bosch-ic-user-ear-blocked:before {
  content: "\e544";
}
.boschicon-bosch-ic-user-bavarian:before {
  content: "\e545";
}
.boschicon-bosch-ic-user-ear-loud:before {
  content: "\e546";
}
.boschicon-bosch-ic-user-walk-crutch:before {
  content: "\e547";
}
.boschicon-bosch-ic-user-mouth-speechless:before {
  content: "\e548";
}
.boschicon-bosch-ic-user-arm-baby:before {
  content: "\e549";
}
.boschicon-bosch-ic-user-arm-missing:before {
  content: "\e54a";
}
.boschicon-bosch-ic-user-eye-eyepatch:before {
  content: "\e54b";
}
.boschicon-bosch-ic-user-strapped:before {
  content: "\e54c";
}
.boschicon-bosch-ic-cleaning-agent:before {
  content: "\e54d";
}
.boschicon-bosch-ic-cup-plate-dirty:before {
  content: "\e54e";
}
.boschicon-bosch-ic-cup-plate-private:before {
  content: "\e54f";
}
.boschicon-bosch-ic-rubbish-apple:before {
  content: "\e550";
}
.boschicon-bosch-ic-rubbish-fish:before {
  content: "\e551";
}
.boschicon-bosch-ic-rubbish-organic-bin:before {
  content: "\e552";
}
.boschicon-bosch-ic-rubbish-paper:before {
  content: "\e553";
}
.boschicon-bosch-ic-rubbish-paper-bin:before {
  content: "\e554";
}
.boschicon-bosch-ic-rubbish-residual-bin:before {
  content: "\e555";
}
.boschicon-bosch-ic-shower:before {
  content: "\e556";
}
.boschicon-bosch-ic-shower-female:before {
  content: "\e557";
}
.boschicon-bosch-ic-shower-male:before {
  content: "\e558";
}
.boschicon-bosch-ic-wardrobe-female:before {
  content: "\e559";
}
.boschicon-bosch-ic-wardrobe-male:before {
  content: "\e55a";
}
.boschicon-bosch-ic-car-top:before {
  content: "\e55b";
}
.boschicon-bosch-ic-label-info:before {
  content: "\e55c";
}
.boschicon-bosch-ic-perspective-view:before {
  content: "\e55d";
}
.boschicon-bosch-ic-road:before {
  content: "\e55e";
}
.boschicon-bosch-ic-screen-split-horizontal:before {
  content: "\e55f";
}
.boschicon-bosch-ic-screen-split-vertical:before {
  content: "\e560";
}
.boschicon-bosch-ic-screenshot-frame:before {
  content: "\e561";
}
.boschicon-bosch-ic-sensor-lidar:before {
  content: "\e562";
}
.boschicon-bosch-ic-tram:before {
  content: "\e563";
}
.boschicon-bosch-ic-video-360-degrees:before {
  content: "\e564";
}
.boschicon-bosch-ic-belt-groove-drives:before {
  content: "\e565";
}
.boschicon-bosch-ic-bottle-champagne:before {
  content: "\e566";
}
.boschicon-bosch-ic-box-falling:before {
  content: "\e567";
}
.boschicon-bosch-ic-box-falling-off-disabled-bold:before {
  content: "\e568";
}
.boschicon-bosch-ic-box-falling-off-disabled-light:before {
  content: "\e569";
}
.boschicon-bosch-ic-brake-pedal:before {
  content: "\e56a";
}
.boschicon-bosch-ic-car-front-light-inside:before {
  content: "\e56b";
}
.boschicon-bosch-ic-car-licence-plate-light:before {
  content: "\e56c";
}
.boschicon-bosch-ic-car-side-light-back:before {
  content: "\e56d";
}
.boschicon-bosch-ic-car-side-light-front:before {
  content: "\e56e";
}
.boschicon-bosch-ic-light-fog-warning:before {
  content: "\e56f";
}
.boschicon-bosch-ic-prism:before {
  content: "\e570";
}
.boschicon-bosch-ic-turnstile:before {
  content: "\e571";
}
.boschicon-bosch-ic-turnstile-open-in-and-outbound:before {
  content: "\e572";
}
.boschicon-bosch-ic-turnstile-open-inbound:before {
  content: "\e573";
}
.boschicon-bosch-ic-turnstile-open-outbound:before {
  content: "\e574";
}
.boschicon-bosch-ic-uwb:before {
  content: "\e575";
}
.boschicon-bosch-ic-heat-reduce-active:before {
  content: "\e576";
}
.boschicon-bosch-ic-heat-reduce-passive:before {
  content: "\e577";
}
.boschicon-bosch-ic-hourglass-add:before {
  content: "\e578";
}
.boschicon-bosch-ic-light-fog:before {
  content: "\e579";
}
.boschicon-bosch-ic-car-turn-signal:before {
  content: "\e57a";
}
.boschicon-bosch-ic-car-turn-signal-left:before {
  content: "\e57b";
}
.boschicon-bosch-ic-car-turn-signal-right:before {
  content: "\e57c";
}
.boschicon-bosch-ic-light-rear:before {
  content: "\e57d";
}
.boschicon-bosch-ic-car-top-turn-signal-front:before {
  content: "\e57e";
}
.boschicon-bosch-ic-car-top-turn-signal-back:before {
  content: "\e57f";
}
.boschicon-bosch-ic-ccu-cm:before {
  content: "\e580";
}
.boschicon-bosch-ic-lung:before {
  content: "\e581";
}
.boschicon-bosch-ic-voice:before {
  content: "\e582";
}
.boschicon-bosch-ic-car-motorcycle:before {
  content: "\e583";
}
.boschicon-bosch-ic-car-turn-signal-side:before {
  content: "\e584";
}
.boschicon-bosch-ic-car-light-parking:before {
  content: "\e585";
}
.boschicon-bosch-ic-phoenix:before {
  content: "\e586";
}
.boschicon-bosch-ic-sensor-air-quality:before {
  content: "\e587";
}
.boschicon-bosch-ic-weather-station:before {
  content: "\e588";
}
.boschicon-bosch-ic-watering-can:before {
  content: "\e589";
}
.boschicon-bosch-ic-propeller:before {
  content: "\e58a";
}
.boschicon-bosch-ic-temperature-fluctuating:before {
  content: "\e58b";
}
.boschicon-bosch-ic-stove-top:before {
  content: "\e58c";
}
.boschicon-bosch-ic-socket:before {
  content: "\e58d";
}
.boschicon-bosch-ic-siren:before {
  content: "\e58e";
}
.boschicon-bosch-ic-sensor-water:before {
  content: "\e58f";
}
.boschicon-bosch-ic-user-run-sensor-motion:before {
  content: "\e590";
}
.boschicon-bosch-ic-robot-lawnmower:before {
  content: "\e591";
}
.boschicon-bosch-ic-robot-cleaning:before {
  content: "\e592";
}
.boschicon-bosch-ic-oven:before {
  content: "\e593";
}
.boschicon-bosch-ic-loudspeaker:before {
  content: "\e594";
}
.boschicon-bosch-ic-keypad:before {
  content: "\e595";
}
.boschicon-bosch-ic-shape-circle-square:before {
  content: "\e596";
}
.boschicon-bosch-ic-garage:before {
  content: "\e597";
}
.boschicon-bosch-ic-fume-hood:before {
  content: "\e598";
}
.boschicon-bosch-ic-freezer:before {
  content: "\e599";
}
.boschicon-bosch-ic-food-processor:before {
  content: "\e59a";
}
.boschicon-bosch-ic-floorplan-rooms:before {
  content: "\e59b";
}
.boschicon-bosch-ic-dryer-tumble:before {
  content: "\e59c";
}
.boschicon-bosch-ic-dryer:before {
  content: "\e59d";
}
.boschicon-bosch-ic-door-window:before {
  content: "\e59e";
}
.boschicon-bosch-ic-door-lock:before {
  content: "\e59f";
}
.boschicon-bosch-ic-door-bell:before {
  content: "\e5a0";
}
.boschicon-bosch-ic-dishwasher:before {
  content: "\e5a1";
}
.boschicon-bosch-ic-dimmer:before {
  content: "\e5a2";
}
.boschicon-bosch-ic-detector-smoke:before {
  content: "\e5a3";
}
.boschicon-bosch-ic-detector-presence:before {
  content: "\e5a4";
}
.boschicon-bosch-ic-cooking:before {
  content: "\e5a5";
}
.boschicon-bosch-ic-coffee-maschine:before {
  content: "\e5a6";
}
.boschicon-bosch-ic-camera-indoor:before {
  content: "\e5a7";
}
.boschicon-bosch-ic-broom-clean:before {
  content: "\e5a8";
}
.boschicon-bosch-ic-blinds:before {
  content: "\e5a9";
}
.boschicon-bosch-ic-bicycle-e-flash:before {
  content: "\e5aa";
}
.boschicon-bosch-ic-finger-domino-trigger:before {
  content: "\e5ab";
}
.boschicon-bosch-ic-automation-start:before {
  content: "\e5ac";
}
.boschicon-bosch-ic-automation:before {
  content: "\e5ad";
}
.boschicon-bosch-ic-sensor-multifunctional:before {
  content: "\e5ae";
}
.boschicon-bosch-ic-plane-top:before {
  content: "\e5af";
}
.boschicon-bosch-ic-shape-square-overlapping:before {
  content: "\e5b0";
}
.boschicon-bosch-ic-square-overlapping:before {
  content: "\e5b1";
}
.boschicon-bosch-ic-battery-car-14v:before {
  content: "\e5b2";
}
.boschicon-bosch-ic-calibration:before {
  content: "\e5b3";
}
.boschicon-bosch-ic-structure-can-bus:before {
  content: "\e5b4";
}
.boschicon-bosch-ic-gears-direction-arrows:before {
  content: "\e5b5";
}
.boschicon-bosch-ic-car-limousine:before {
  content: "\e5b6";
}
.boschicon-bosch-ic-molecule-gas:before {
  content: "\e5b7";
}
.boschicon-bosch-ic-money-euro:before {
  content: "\e5b8";
}
.boschicon-bosch-ic-revolution-rpm:before {
  content: "\e5b9";
}
.boschicon-bosch-ic-rikscha-e:before {
  content: "\e5ba";
}
.boschicon-bosch-ic-sensor-circle:before {
  content: "\e5bb";
}
.boschicon-bosch-ic-speedometer-high:before {
  content: "\e5bc";
}
.boschicon-bosch-ic-steering-wheel-arrows:before {
  content: "\e5bd";
}
.boschicon-bosch-ic-tablet-checklist:before {
  content: "\e5be";
}
.boschicon-bosch-ic-petrol-charging-station-hybrid:before {
  content: "\e5bf";
}
.boschicon-bosch-ic-weather-cloud-sun-rain-snow:before {
  content: "\e5c0";
}
.boschicon-bosch-ic-light-half:before {
  content: "\e5c1";
}
.boschicon-bosch-ic-video-sensor:before {
  content: "\e5c2";
}
.boschicon-bosch-ic-binary-desktop:before {
  content: "\e5c3";
}
.boschicon-bosch-ic-truck-delivery:before {
  content: "\e5c4";
}
.boschicon-bosch-ic-van-delivery:before {
  content: "\e5c5";
}
.boschicon-bosch-ic-fullscreen-user:before {
  content: "\e5c6";
}
.boschicon-bosch-ic-user-helmet-motorcycle:before {
  content: "\e5c7";
}
.boschicon-bosch-ic-user-hand-disabled-light:before {
  content: "\e5c8";
}
.boschicon-bosch-ic-user-hand-disabled-bold:before {
  content: "\e5c9";
}
.boschicon-bosch-ic-link-connected:before {
  content: "\e5ca";
}
.boschicon-bosch-ic-gate-open:before {
  content: "\e5cb";
}
.boschicon-bosch-ic-box-checkmark:before {
  content: "\e5cc";
}
.boschicon-bosch-ic-box-place:before {
  content: "\e5cd";
}
.boschicon-bosch-ic-logistics-dock:before {
  content: "\e5ce";
}
.boschicon-bosch-ic-barcode-scan-logistics:before {
  content: "\e5cf";
}
.boschicon-bosch-ic-locator-tracking:before {
  content: "\e5d0";
}
.boschicon-bosch-ic-car-light-trunk:before {
  content: "\e5d1";
}
.boschicon-bosch-ic-car-light-glove-compartment:before {
  content: "\e5d2";
}
.boschicon-bosch-ic-car-light-dashboard:before {
  content: "\e5d3";
}
.boschicon-bosch-ic-fire-leaf:before {
  content: "\e5d4";
}
.boschicon-bosch-ic-laptop-hacker:before {
  content: "\e5d5";
}
.boschicon-bosch-ic-hands-drops:before {
  content: "\e5d6";
}
.boschicon-bosch-ic-team-disabled-bold:before {
  content: "\e5d7";
}
.boschicon-bosch-ic-team-disabled-light:before {
  content: "\e5d8";
}
.boschicon-bosch-ic-user-head-arm:before {
  content: "\e5d9";
}
.boschicon-bosch-ic-user-head-hand-disabled-bold:before {
  content: "\e5da";
}
.boschicon-bosch-ic-user-head-hand-disabled-light:before {
  content: "\e5db";
}
.boschicon-bosch-ic-welcome-disabled-bold:before {
  content: "\e5dc";
}
.boschicon-bosch-ic-welcome-disabled-light:before {
  content: "\e5dd";
}
.boschicon-bosch-ic-female-male-arrows:before {
  content: "\e5de";
}
.boschicon-bosch-ic-broccoli:before {
  content: "\e5df";
}
.boschicon-bosch-ic-chicken:before {
  content: "\e5e0";
}
.boschicon-bosch-ic-cow:before {
  content: "\e5e1";
}
.boschicon-bosch-ic-deer:before {
  content: "\e5e2";
}
.boschicon-bosch-ic-fish:before {
  content: "\e5e3";
}
.boschicon-bosch-ic-garlic:before {
  content: "\e5e4";
}
.boschicon-bosch-ic-hotpepper:before {
  content: "\e5e5";
}
.boschicon-bosch-ic-pig:before {
  content: "\e5e6";
}
.boschicon-bosch-ic-rabbit:before {
  content: "\e5e7";
}
.boschicon-bosch-ic-sheep:before {
  content: "\e5e8";
}
.boschicon-bosch-ic-shell:before {
  content: "\e5e9";
}
.boschicon-bosch-ic-shrimp:before {
  content: "\e5ea";
}
.boschicon-bosch-ic-car-seat:before {
  content: "\e5eb";
}
.boschicon-bosch-ic-car-seat-steering-wheel:before {
  content: "\e5ec";
}
.boschicon-bosch-ic-car-side-arrow-back:before {
  content: "\e5ed";
}
.boschicon-bosch-ic-car-side-arrow-front:before {
  content: "\e5ee";
}
.boschicon-bosch-ic-car-steering-left-disabled-bold:before {
  content: "\e5ef";
}
.boschicon-bosch-ic-car-steering-left-disabled-light:before {
  content: "\e5f0";
}
.boschicon-bosch-ic-car-steering-left-lle:before {
  content: "\e5f1";
}
.boschicon-bosch-ic-car-steering-left-lle-disabled-bold:before {
  content: "\e5f2";
}
.boschicon-bosch-ic-car-steering-left-lle-disabled-light:before {
  content: "\e5f3";
}
.boschicon-bosch-ic-car-steering-right-disabled-bold:before {
  content: "\e5f4";
}
.boschicon-bosch-ic-car-steering-right-disabled-light:before {
  content: "\e5f5";
}
.boschicon-bosch-ic-car-steering-right-rle:before {
  content: "\e5f6";
}
.boschicon-bosch-ic-car-steering-right-rle-disabled-bold:before {
  content: "\e5f7";
}
.boschicon-bosch-ic-car-steering-right-rle-disabled-light:before {
  content: "\e5f8";
}
.boschicon-bosch-ic-cars-traffic-left-right:before {
  content: "\e5f9";
}
.boschicon-bosch-ic-configuration-wrench:before {
  content: "\e5fa";
}
.boschicon-bosch-ic-hook:before {
  content: "\e5fb";
}
.boschicon-bosch-ic-hook-arrows:before {
  content: "\e5fc";
}
.boschicon-bosch-ic-hook-arrows-wiperblade:before {
  content: "\e5fd";
}
.boschicon-bosch-ic-hook-disabled-bold:before {
  content: "\e5fe";
}
.boschicon-bosch-ic-hook-disabled-light:before {
  content: "\e5ff";
}
.boschicon-bosch-ic-info-i-frame-add:before {
  content: "\e600";
}
.boschicon-bosch-ic-search-number:before {
  content: "\e601";
}
.boschicon-bosch-ic-search-number-add:before {
  content: "\e602";
}
.boschicon-bosch-ic-set:before {
  content: "\e603";
}
.boschicon-bosch-ic-speedometer-speed-high:before {
  content: "\e604";
}
.boschicon-bosch-ic-windshield-wiper-clean:before {
  content: "\e605";
}
.boschicon-bosch-ic-windshield-wiper-double:before {
  content: "\e606";
}
.boschicon-bosch-ic-wiper-adapter:before {
  content: "\e607";
}
.boschicon-bosch-ic-wiper-blade:before {
  content: "\e608";
}
.boschicon-bosch-ic-wiper-blade-arrow:before {
  content: "\e609";
}
.boschicon-bosch-ic-wiper-blade-eco:before {
  content: "\e60a";
}
.boschicon-bosch-ic-wiper-blade-mm:before {
  content: "\e60b";
}
.boschicon-bosch-ic-wiper-blade-mm-arrows:before {
  content: "\e60c";
}
.boschicon-bosch-ic-wiper-blade-spoiler:before {
  content: "\e60d";
}
.boschicon-bosch-ic-wiper-blade-twin:before {
  content: "\e60e";
}
.boschicon-bosch-ic-wiper-flatblade:before {
  content: "\e60f";
}
.boschicon-bosch-ic-wiper-flatblade-aeroeco:before {
  content: "\e610";
}
.boschicon-bosch-ic-wiper-flatblade-aerofit:before {
  content: "\e611";
}
.boschicon-bosch-ic-wiper-flatblade-aerotwin:before {
  content: "\e612";
}
.boschicon-bosch-ic-wiper-flatblade-arrow:before {
  content: "\e613";
}
.boschicon-bosch-ic-wiper-flatblade-mm:before {
  content: "\e614";
}
.boschicon-bosch-ic-wiper-flatblade-mm-arrows:before {
  content: "\e615";
}
.boschicon-bosch-ic-wiper-rubber:before {
  content: "\e616";
}
.boschicon-bosch-ic-address-control-system:before {
  content: "\e617";
}
.boschicon-bosch-ic-balcony:before {
  content: "\e618";
}
.boschicon-bosch-ic-battery-car-45-degree-arrow:before {
  content: "\e619";
}
.boschicon-bosch-ic-boiler-warning:before {
  content: "\e61a";
}
.boschicon-bosch-ic-book-wrench:before {
  content: "\e61b";
}
.boschicon-bosch-ic-car-driver-passenger-arrows:before {
  content: "\e61c";
}
.boschicon-bosch-ic-car-licence-plate:before {
  content: "\e61d";
}
.boschicon-bosch-ic-car-side-arrow-back-tires:before {
  content: "\e61e";
}
.boschicon-bosch-ic-car-side-arrow-front-tires:before {
  content: "\e61f";
}
.boschicon-bosch-ic-car-side-half-arrow-back:before {
  content: "\e620";
}
.boschicon-bosch-ic-car-steering-left-arrow:before {
  content: "\e621";
}
.boschicon-bosch-ic-car-steering-left-passenger-arrow:before {
  content: "\e622";
}
.boschicon-bosch-ic-car-steering-right-arrow:before {
  content: "\e623";
}
.boschicon-bosch-ic-car-steering-right-passenger-arrow:before {
  content: "\e624";
}
.boschicon-bosch-ic-chess-board-transparency:before {
  content: "\e625";
}
.boschicon-bosch-ic-cloud-locked:before {
  content: "\e626";
}
.boschicon-bosch-ic-conference-system:before {
  content: "\e627";
}
.boschicon-bosch-ic-cycletime-quarter-arrows-interval:before {
  content: "\e628";
}
.boschicon-bosch-ic-desktop-connectivity:before {
  content: "\e629";
}
.boschicon-bosch-ic-hierarchy-checkmark:before {
  content: "\e62a";
}
.boschicon-bosch-ic-home-office:before {
  content: "\e62b";
}
.boschicon-bosch-ic-home-semi-detached-house:before {
  content: "\e62c";
}
.boschicon-bosch-ic-kitchen:before {
  content: "\e62d";
}
.boschicon-bosch-ic-lambda-arrows-length:before {
  content: "\e62e";
}
.boschicon-bosch-ic-lambda-oxygen:before {
  content: "\e62f";
}
.boschicon-bosch-ic-lambda-sensor-cores:before {
  content: "\e630";
}
.boschicon-bosch-ic-panel-control:before {
  content: "\e631";
}
.boschicon-bosch-ic-prison:before {
  content: "\e632";
}
.boschicon-bosch-ic-prison-cell:before {
  content: "\e633";
}
.boschicon-bosch-ic-radio-broadcasting:before {
  content: "\e634";
}
.boschicon-bosch-ic-robot-body:before {
  content: "\e635";
}
.boschicon-bosch-ic-robot-body-arrow:before {
  content: "\e636";
}
.boschicon-bosch-ic-skyscraper-danger:before {
  content: "\e637";
}
.boschicon-bosch-ic-skyscraper-flat:before {
  content: "\e638";
}
.boschicon-bosch-ic-skyscraper-flat-floor-low:before {
  content: "\e639";
}
.boschicon-bosch-ic-skyscraper-flat-floor-middle:before {
  content: "\e63a";
}
.boschicon-bosch-ic-skyscraper-flat-floor-top:before {
  content: "\e63b";
}
.boschicon-bosch-ic-skyscraper-flat-semi-detached:before {
  content: "\e63c";
}
.boschicon-bosch-ic-skyscraper-locked:before {
  content: "\e63d";
}
.boschicon-bosch-ic-skyscraper-settings:before {
  content: "\e63e";
}
.boschicon-bosch-ic-team-signpost:before {
  content: "\e63f";
}
.boschicon-bosch-ic-toaster-hand-warning:before {
  content: "\e640";
}
.boschicon-bosch-ic-user-arrows-heart:before {
  content: "\e641";
}
.boschicon-bosch-ic-user-graduate-chat:before {
  content: "\e642";
}
.boschicon-bosch-ic-user-head-mask:before {
  content: "\e643";
}
.boschicon-bosch-ic-warehouse:before {
  content: "\e644";
}
.boschicon-bosch-ic-world-paperplane:before {
  content: "\e645";
}
.boschicon-bosch-ic-aperture:before {
  content: "\e646";
}
.boschicon-bosch-ic-hook-wiperblade-arrows:before {
  content: "\e647";
}
.boschicon-bosch-ic-image-remove:before {
  content: "\e648";
}
.boschicon-bosch-ic-image-checked:before {
  content: "\e649";
}
.boschicon-bosch-ic-user-setting:before {
  content: "\e64a";
}
.boschicon-bosch-ic-apartment-in-house-terraced:before {
  content: "\e64b";
}
.boschicon-bosch-ic-apartment-in-house:before {
  content: "\e64c";
}
.boschicon-bosch-ic-appliance-heat-pump-leaf-add:before {
  content: "\e64d";
}
.boschicon-bosch-ic-appliance-heat-pump-leaf-reload:before {
  content: "\e64e";
}
.boschicon-bosch-ic-appliance-heat-pump-oil-refresh:before {
  content: "\e64f";
}
.boschicon-bosch-ic-appliance-water-drop:before {
  content: "\e650";
}
.boschicon-bosch-ic-appliance-water-sun:before {
  content: "\e651";
}
.boschicon-bosch-ic-brake-pedal-thump-up:before {
  content: "\e652";
}
.boschicon-bosch-ic-call-plus:before {
  content: "\e653";
}
.boschicon-bosch-ic-car-battery-charging:before {
  content: "\e654";
}
.boschicon-bosch-ic-cloud-share:before {
  content: "\e655";
}
.boschicon-bosch-ic-crankshaft:before {
  content: "\e656";
}
.boschicon-bosch-ic-detector-motion:before {
  content: "\e657";
}
.boschicon-bosch-ic-device-analog:before {
  content: "\e658";
}
.boschicon-bosch-ic-device-digital:before {
  content: "\e659";
}
.boschicon-bosch-ic-externallink-landscape:before {
  content: "\e65a";
}
.boschicon-bosch-ic-gears-interlocked-arrow:before {
  content: "\e65b";
}
.boschicon-bosch-ic-gearshift-reverse-gear:before {
  content: "\e65c";
}
.boschicon-bosch-ic-glove:before {
  content: "\e65d";
}
.boschicon-bosch-ic-hand-warranty:before {
  content: "\e65e";
}
.boschicon-bosch-ic-heating-floor-radiator:before {
  content: "\e65f";
}
.boschicon-bosch-ic-home-appliance-heat-pump-air-in:before {
  content: "\e660";
}
.boschicon-bosch-ic-home-appliance-heat-pump-air-out:before {
  content: "\e661";
}
.boschicon-bosch-ic-lightbulb-gear:before {
  content: "\e662";
}
.boschicon-bosch-ic-line-open-closed:before {
  content: "\e663";
}
.boschicon-bosch-ic-road-disabled-bold:before {
  content: "\e664";
}
.boschicon-bosch-ic-road-disabled-light:before {
  content: "\e665";
}
.boschicon-bosch-ic-shower-drop-1:before {
  content: "\e666";
}
.boschicon-bosch-ic-shower-drop-2:before {
  content: "\e667";
}
.boschicon-bosch-ic-shower-drop-3:before {
  content: "\e668";
}
.boschicon-bosch-ic-shower-drop-4:before {
  content: "\e669";
}
.boschicon-bosch-ic-sinus-curve:before {
  content: "\e66a";
}
.boschicon-bosch-ic-team-3:before {
  content: "\e66b";
}
.boschicon-bosch-ic-team-4:before {
  content: "\e66c";
}
.boschicon-bosch-ic-team-5:before {
  content: "\e66d";
}
.boschicon-bosch-ic-test-tube:before {
  content: "\e66e";
}
.boschicon-bosch-ic-truck-car-motorcycle-arrows:before {
  content: "\e66f";
}
.boschicon-bosch-ic-weather-cloud-dust:before {
  content: "\e670";
}
.boschicon-bosch-ic-windshield-wiper-double-parallel-left:before {
  content: "\e671";
}
.boschicon-bosch-ic-windshield-wiper-double-parallel-right:before {
  content: "\e672";
}
.boschicon-bosch-ic-world-pin:before {
  content: "\e673";
}
.boschicon-bosch-ic-sign-warning-slope:before {
  content: "\e674";
}
.boschicon-bosch-ic-bond-laser:before {
  content: "\e675";
}
.boschicon-bosch-ic-bond-ribbon-thick:before {
  content: "\e676";
}
.boschicon-bosch-ic-bond-ribbon-thin:before {
  content: "\e677";
}
.boschicon-bosch-ic-bond-wire-thick:before {
  content: "\e678";
}
.boschicon-bosch-ic-bond-wire-thin:before {
  content: "\e679";
}
.boschicon-bosch-ic-clamp-connection-insulation:before {
  content: "\e67a";
}
.boschicon-bosch-ic-clamp-connection-stamping-grid:before {
  content: "\e67b";
}
.boschicon-bosch-ic-clinching:before {
  content: "\e67c";
}
.boschicon-bosch-ic-connector:before {
  content: "\e67d";
}
.boschicon-bosch-ic-connector-potting:before {
  content: "\e67e";
}
.boschicon-bosch-ic-element-damping:before {
  content: "\e67f";
}
.boschicon-bosch-ic-element-pressure-compensation:before {
  content: "\e680";
}
.boschicon-bosch-ic-flanging:before {
  content: "\e681";
}
.boschicon-bosch-ic-gap-pad:before {
  content: "\e682";
}
.boschicon-bosch-ic-heat-sink:before {
  content: "\e683";
}
.boschicon-bosch-ic-hook-clipsing:before {
  content: "\e684";
}
.boschicon-bosch-ic-interconnection-board-to-board:before {
  content: "\e685";
}
.boschicon-bosch-ic-labyrinth-geometry:before {
  content: "\e686";
}
.boschicon-bosch-ic-lacquering-pcb:before {
  content: "\e687";
}
.boschicon-bosch-ic-magnetic-rotor:before {
  content: "\e688";
}
.boschicon-bosch-ic-magnetic-switch:before {
  content: "\e689";
}
.boschicon-bosch-ic-pin-pressfit:before {
  content: "\e68a";
}
.boschicon-bosch-ic-power-contact:before {
  content: "\e68b";
}
.boschicon-bosch-ic-rivet:before {
  content: "\e68c";
}
.boschicon-bosch-ic-seal-ring:before {
  content: "\e68d";
}
.boschicon-bosch-ic-sealing-cipg:before {
  content: "\e68e";
}
.boschicon-bosch-ic-sealing-solid:before {
  content: "\e68f";
}
.boschicon-bosch-ic-sealing-tongue-groove:before {
  content: "\e690";
}
.boschicon-bosch-ic-shrinking-arrows-circle:before {
  content: "\e691";
}
.boschicon-bosch-ic-solder-selective:before {
  content: "\e692";
}
.boschicon-bosch-ic-spring-mechanical:before {
  content: "\e693";
}
.boschicon-bosch-ic-staking-hot-air:before {
  content: "\e694";
}
.boschicon-bosch-ic-stitching:before {
  content: "\e695";
}
.boschicon-bosch-ic-thermal-inlay:before {
  content: "\e696";
}
.boschicon-bosch-ic-thermal-via:before {
  content: "\e697";
}
.boschicon-bosch-ic-tube:before {
  content: "\e698";
}
.boschicon-bosch-ic-tube-gapfiller:before {
  content: "\e699";
}
.boschicon-bosch-ic-tube-gelling:before {
  content: "\e69a";
}
.boschicon-bosch-ic-tube-gluing:before {
  content: "\e69b";
}
.boschicon-bosch-ic-tube-thermal-adhesive:before {
  content: "\e69c";
}
.boschicon-bosch-ic-tube-underfill:before {
  content: "\e69d";
}
.boschicon-bosch-ic-welding-laser:before {
  content: "\e69e";
}
.boschicon-bosch-ic-welding-resistance:before {
  content: "\e69f";
}
.boschicon-bosch-ic-welding-ultrasonic:before {
  content: "\e6a0";
}
.boschicon-bosch-ic-air-condition-cassette:before {
  content: "\e6a1";
}
.boschicon-bosch-ic-air-condition-ceiling:before {
  content: "\e6a2";
}
.boschicon-bosch-ic-air-condition-convertible:before {
  content: "\e6a3";
}
.boschicon-bosch-ic-air-condition-duct:before {
  content: "\e6a4";
}
.boschicon-bosch-ic-air-condition-floor:before {
  content: "\e6a5";
}
.boschicon-bosch-ic-air-condition-outdoor:before {
  content: "\e6a6";
}
.boschicon-bosch-ic-air-condition-outdoor-hp:before {
  content: "\e6a7";
}
.boschicon-bosch-ic-air-condition-outdoor-hr:before {
  content: "\e6a8";
}
.boschicon-bosch-ic-air-condition-swing-horizontal:before {
  content: "\e6a9";
}
.boschicon-bosch-ic-air-condition-swing-vertical:before {
  content: "\e6aa";
}
.boschicon-bosch-ic-air-condition-wall:before {
  content: "\e6ab";
}
.boschicon-bosch-ic-angle-curvature-motorcycle:before {
  content: "\e6ac";
}
.boschicon-bosch-ic-appliance-hot:before {
  content: "\e6ad";
}
.boschicon-bosch-ic-appliance-water-hot:before {
  content: "\e6ae";
}
.boschicon-bosch-ic-appliances-hot:before {
  content: "\e6af";
}
.boschicon-bosch-ic-arrows-left-right-a:before {
  content: "\e6b0";
}
.boschicon-bosch-ic-arrows-left-right-exchange:before {
  content: "\e6b1";
}
.boschicon-bosch-ic-arrows-left-right-exchange-unit:before {
  content: "\e6b2";
}
.boschicon-bosch-ic-arrows-left-right-ice:before {
  content: "\e6b3";
}
.boschicon-bosch-ic-assembly-line-robot:before {
  content: "\e6b4";
}
.boschicon-bosch-ic-back-left-small:before {
  content: "\e6b5";
}
.boschicon-bosch-ic-bacteria:before {
  content: "\e6b6";
}
.boschicon-bosch-ic-battery-car-drop-leakage-proof:before {
  content: "\e6b7";
}
.boschicon-bosch-ic-battery-car-hourglass:before {
  content: "\e6b8";
}
.boschicon-bosch-ic-brake-disk-arrow:before {
  content: "\e6b9";
}
.boschicon-bosch-ic-brake-disk-compound:before {
  content: "\e6ba";
}
.boschicon-bosch-ic-building-religious-church:before {
  content: "\e6bb";
}
.boschicon-bosch-ic-calendar-sheet:before {
  content: "\e6bc";
}
.boschicon-bosch-ic-caliper:before {
  content: "\e6bd";
}
.boschicon-bosch-ic-car-middle-arrow:before {
  content: "\e6be";
}
.boschicon-bosch-ic-checkmark-bold:before {
  content: "\e6bf";
}
.boschicon-bosch-ic-close-small:before {
  content: "\e6c0";
}
.boschicon-bosch-ic-data-center:before {
  content: "\e6c1";
}
.boschicon-bosch-ic-dot:before {
  content: "\e6c2";
}
.boschicon-bosch-ic-down-small:before {
  content: "\e6c3";
}
.boschicon-bosch-ic-e-call:before {
  content: "\e6c4";
}
.boschicon-bosch-ic-fire-extinguisher:before {
  content: "\e6c5";
}
.boschicon-bosch-ic-fire-off-bold:before {
  content: "\e6c6";
}
.boschicon-bosch-ic-fire-off-light:before {
  content: "\e6c7";
}
.boschicon-bosch-ic-forward-right-small:before {
  content: "\e6c8";
}
.boschicon-bosch-ic-health-warning:before {
  content: "\e6c9";
}
.boschicon-bosch-ic-ignition-coil-pencil:before {
  content: "\e6ca";
}
.boschicon-bosch-ic-ignition-condensor:before {
  content: "\e6cb";
}
.boschicon-bosch-ic-ignition-contact-set:before {
  content: "\e6cc";
}
.boschicon-bosch-ic-ignition-distributor-cap:before {
  content: "\e6cd";
}
.boschicon-bosch-ic-ignition-distributor-rotor:before {
  content: "\e6ce";
}
.boschicon-bosch-ic-ignition-module:before {
  content: "\e6cf";
}
.boschicon-bosch-ic-less-minimize-bold:before {
  content: "\e6d0";
}
.boschicon-bosch-ic-leaf-plus-boost:before {
  content: "\e6d1";
}
.boschicon-bosch-ic-sensor-dynamic:before {
  content: "\e6d2";
}
.boschicon-bosch-ic-sensor-radar:before {
  content: "\e6d3";
}
.boschicon-bosch-ic-up-small:before {
  content: "\e6d4";
}
.boschicon-bosch-ic-water-ladder-off-bold:before {
  content: "\e6d5";
}
.boschicon-bosch-ic-water-ladder-off-light:before {
  content: "\e6d6";
}
.boschicon-bosch-ic-nose-desodorize:before {
  content: "\e6d7";
}
.boschicon-bosch-ic-nose-fine-dust:before {
  content: "\e6d8";
}
.boschicon-bosch-ic-smartcity-camera:before {
  content: "\e6d9";
}
.boschicon-bosch-ic-heating-underfloor:before {
  content: "\e6da";
}
.boschicon-bosch-ic-drill-bit:before {
  content: "\e6db";
}
.boschicon-bosch-ic-parameter-process:before {
  content: "\e6dc";
}
.boschicon-bosch-ic-car-side-plug-flash:before {
  content: "\e6dd";
}
.boschicon-bosch-ic-car-side-plug-engine-flash:before {
  content: "\e6de";
}
.boschicon-bosch-ic-car-side-engine-flash:before {
  content: "\e6df";
}
.boschicon-bosch-ic-car-side-hydrogen-flash:before {
  content: "\e6e0";
}
.boschicon-bosch-ic-car-side-engine:before {
  content: "\e6e1";
}
.boschicon-bosch-ic-tunnel:before {
  content: "\e6e2";
}
.boschicon-bosch-ic-stadium-sport:before {
  content: "\e6e3";
}
.boschicon-bosch-ic-refinery-oil:before {
  content: "\e6e4";
}
.boschicon-bosch-ic-power-plants:before {
  content: "\e6e5";
}
.boschicon-bosch-ic-hammer-chisel:before {
  content: "\e6e6";
}
.boschicon-bosch-ic-glass-cocktail:before {
  content: "\e6e7";
}
.boschicon-bosch-ic-traffic-light:before {
  content: "\e6e8";
}
.boschicon-bosch-ic-vehicle-off-road:before {
  content: "\e6e9";
}
.boschicon-bosch-ic-tablet-finger:before {
  content: "\e6ea";
}
.boschicon-bosch-ic-scooter-kick:before {
  content: "\e6eb";
}
.boschicon-bosch-ic-handlebar-motorcycle:before {
  content: "\e6ec";
}
.boschicon-bosch-ic-display-motorcycle:before {
  content: "\e6ed";
}
.boschicon-bosch-ic-case-label-product:before {
  content: "\e6ee";
}
.boschicon-bosch-ic-case-label:before {
  content: "\e6ef";
}
.boschicon-bosch-ic-product-falling:before {
  content: "\e6f0";
}
.boschicon-bosch-ic-product-checkmark:before {
  content: "\e6f1";
}
.boschicon-bosch-ic-product-broken:before {
  content: "\e6f2";
}
.boschicon-bosch-ic-lightbulb-halogen-rays-bright:before {
  content: "\e6f3";
}
.boschicon-bosch-ic-lightbulb-halogen-rays:before {
  content: "\e6f4";
}
.boschicon-bosch-ic-lamp-gas-discharge-rays:before {
  content: "\e6f5";
}
.boschicon-bosch-ic-home-lightbulb-off-disabled-bold:before {
  content: "\e6f6";
}
.boschicon-bosch-ic-home-lightbulb-off-disabled-light:before {
  content: "\e6f7";
}
.boschicon-bosch-ic-fork-lift-boxes:before {
  content: "\e6f8";
}
.boschicon-bosch-ic-harbour-ship-cargo:before {
  content: "\e6f9";
}
.boschicon-bosch-ic-rollercoaster:before {
  content: "\e6fa";
}
.boschicon-bosch-ic-sound-off-light:before {
  content: "\e6fb";
}
.boschicon-bosch-ic-sound-off-bold:before {
  content: "\e6fc";
}
.boschicon-bosch-ic-car-door:before {
  content: "\e6fd";
}
.boschicon-bosch-ic-car-door-tailgate:before {
  content: "\e6fe";
}
.boschicon-bosch-ic-car-door-split-rear:before {
  content: "\e6ff";
}
.boschicon-bosch-ic-car-door-rear:before {
  content: "\e700";
}
.boschicon-bosch-ic-drill-driver-cordless:before {
  content: "\e701";
}
.boschicon-bosch-ic-virus:before {
  content: "\e702";
}
.boschicon-bosch-ic-ice-temperature:before {
  content: "\e703";
}
.boschicon-bosch-ic-sensor-ultrasonic:before {
  content: "\e704";
}
.boschicon-bosch-ic-user-artificial-intelligence:before {
  content: "\e705";
}
.boschicon-bosch-ic-detector-leak:before {
  content: "\e706";
}
.boschicon-bosch-ic-car-seat-add:before {
  content: "\e707";
}
.boschicon-bosch-ic-car-shuttle-robo:before {
  content: "\e708";
}
.boschicon-bosch-ic-caravan:before {
  content: "\e709";
}
.boschicon-bosch-ic-user-water-swimming:before {
  content: "\e70a";
}
.boschicon-bosch-ic-square-add:before {
  content: "\e70b";
}
.boschicon-bosch-ic-map-zoom-in:before {
  content: "\e70c";
}
.boschicon-bosch-ic-map-arrow-pop-out:before {
  content: "\e70d";
}
.boschicon-bosch-ic-home-user-in-error:before {
  content: "\e70e";
}
.boschicon-bosch-ic-home-arrow-right-in-denied:before {
  content: "\e70f";
}
.boschicon-bosch-ic-home-arrow-right-in-check:before {
  content: "\e710";
}
.boschicon-bosch-ic-home-arrow-right-in:before {
  content: "\e711";
}
.boschicon-bosch-ic-home-arrow-left-out:before {
  content: "\e712";
}
.boschicon-bosch-ic-file-error:before {
  content: "\e713";
}
.boschicon-bosch-ic-dealer-details-missing:before {
  content: "\e714";
}
.boschicon-bosch-ic-dealer-details-check:before {
  content: "\e715";
}
.boschicon-bosch-ic-components-add:before {
  content: "\e716";
}
.boschicon-bosch-ic-machine-cell:before {
  content: "\e717";
}
.boschicon-bosch-ic-wheel-rim-clean:before {
  content: "\e718";
}
.boschicon-bosch-ic-gas-can:before {
  content: "\e719";
}
.boschicon-bosch-ic-steps-locator:before {
  content: "\e71a";
}
.boschicon-bosch-ic-panel-control-manual:before {
  content: "\e71b";
}
.boschicon-bosch-ic-socket-europe:before {
  content: "\e71c";
}
.boschicon-bosch-ic-tripod:before {
  content: "\e71d";
}
.boschicon-bosch-ic-document-dxf:before {
  content: "\e71e";
}
.boschicon-bosch-ic-document-dwg:before {
  content: "\e71f";
}
.boschicon-bosch-ic-hook-wiperblade:before {
  content: "\e720";
}
.boschicon-bosch-ic-traffic-cone:before {
  content: "\e721";
}
.boschicon-bosch-ic-folder-locked:before {
  content: "\e722";
}
.boschicon-bosch-ic-package-self-service:before {
  content: "\e723";
}
.boschicon-bosch-ic-drop-ice:before {
  content: "\e724";
}
.boschicon-bosch-ic-lamp-gas-discharge:before {
  content: "\e725";
}
.boschicon-bosch-ic-home-basement:before {
  content: "\e726";
}
.boschicon-bosch-ic-link-edit:before {
  content: "\e727";
}
.boschicon-bosch-ic-table-header-add:before {
  content: "\e728";
}
.boschicon-bosch-ic-table-column-add-after:before {
  content: "\e729";
}
.boschicon-bosch-ic-table-row-add-above:before {
  content: "\e72a";
}
.boschicon-bosch-ic-table-column-add-before:before {
  content: "\e72b";
}
.boschicon-bosch-ic-table-row-add-below:before {
  content: "\e72c";
}
.boschicon-bosch-ic-table-cells-split:before {
  content: "\e72d";
}
.boschicon-bosch-ic-table-cells-merge:before {
  content: "\e72e";
}
.boschicon-bosch-ic-table-row-delete:before {
  content: "\e72f";
}
.boschicon-bosch-ic-text-indent-arrow:before {
  content: "\e730";
}
.boschicon-bosch-ic-text-outdent-arrow:before {
  content: "\e731";
}
.boschicon-bosch-ic-car-side-flash:before {
  content: "\e732";
}
.boschicon-bosch-ic-battery-plug-flash:before {
  content: "\e733";
}
.boschicon-bosch-ic-car-chip:before {
  content: "\e734";
}
.boschicon-bosch-ic-car-cloud-upload:before {
  content: "\e735";
}
.boschicon-bosch-ic-circle-parking:before {
  content: "\e736";
}
.boschicon-bosch-ic-engine-battery-flash:before {
  content: "\e737";
}
.boschicon-bosch-ic-engine-battery-flash-plug:before {
  content: "\e738";
}
.boschicon-bosch-ic-engine-combustion:before {
  content: "\e739";
}
.boschicon-bosch-ic-user-head-scan:before {
  content: "\e73a";
}
.boschicon-bosch-ic-bluetooth-off-disabled-bold:before {
  content: "\e73b";
}
.boschicon-bosch-ic-bluetooth-off-disabled-light:before {
  content: "\e73c";
}
.boschicon-bosch-ic-communicator-off-disabled-bold:before {
  content: "\e73d";
}
.boschicon-bosch-ic-communicator-off-disabled-light:before {
  content: "\e73e";
}
.boschicon-bosch-ic-connectivity-off-disabled-bold:before {
  content: "\e73f";
}
.boschicon-bosch-ic-connectivity-off-disabled-light:before {
  content: "\e740";
}
.boschicon-bosch-ic-globe-off-disabled-bold:before {
  content: "\e741";
}
.boschicon-bosch-ic-globe-off-disabled-light:before {
  content: "\e742";
}
.boschicon-bosch-ic-lamp-led:before {
  content: "\e743";
}
.boschicon-bosch-ic-wifi-off-disabled-bold:before {
  content: "\e744";
}
.boschicon-bosch-ic-wifi-off-disabled-light:before {
  content: "\e745";
}
.boschicon-bosch-ic-wheel-vehicle:before {
  content: "\e746";
}
.boschicon-bosch-ic-abort-frame:before {
  content: "\e886";
}
.boschicon-bosch-ic-arrow-down-frame:before {
  content: "\e887";
}
.boschicon-bosch-ic-arrow-left-down-frame:before {
  content: "\e888";
}
.boschicon-bosch-ic-arrow-left-frame:before {
  content: "\e889";
}
.boschicon-bosch-ic-arrow-left-up-frame:before {
  content: "\e88a";
}
.boschicon-bosch-ic-arrow-right-down-frame:before {
  content: "\e88b";
}
.boschicon-bosch-ic-arrow-right-frame:before {
  content: "\e88c";
}
.boschicon-bosch-ic-arrow-right-up-frame:before {
  content: "\e88d";
}
.boschicon-bosch-ic-arrow-up-frame:before {
  content: "\e88e";
}
.boschicon-bosch-ic-asiapacific-frame:before {
  content: "\e88f";
}
.boschicon-bosch-ic-cash-frame:before {
  content: "\e890";
}
.boschicon-bosch-ic-checkmark-frame:before {
  content: "\e891";
}
.boschicon-bosch-ic-copyright-frame:before {
  content: "\e892";
}
.boschicon-bosch-ic-download-frame:before {
  content: "\e893";
}
.boschicon-bosch-ic-europe-frame:before {
  content: "\e894";
}
.boschicon-bosch-ic-lock-closed-frame:before {
  content: "\e895";
}
.boschicon-bosch-ic-lock-open-frame:before {
  content: "\e896";
}
.boschicon-bosch-ic-my-brand-frame:before {
  content: "\e897";
}
.boschicon-bosch-ic-northamerica-frame:before {
  content: "\e898";
}
.boschicon-bosch-ic-problem-frame:before {
  content: "\e899";
}
.boschicon-bosch-ic-promotion-frame:before {
  content: "\e89a";
}
.boschicon-bosch-ic-question-frame:before {
  content: "\e89b";
}
.boschicon-bosch-ic-share-frame:before {
  content: "\e89c";
}
.boschicon-bosch-ic-southamerica-frame:before {
  content: "\e89d";
}
.boschicon-bosch-ic-start-play-frame:before {
  content: "\e89e";
}
.boschicon-bosch-ic-upload-frame:before {
  content: "\e89f";
}
.boschicon-bosch-ic-world-frame:before {
  content: "\e8a0";
}
.boschicon-bosch-ic-add-frame:before {
  content: "\e8a1";
}
.boschicon-bosch-ic-call-deny-frame:before {
  content: "\e8a2";
}
.boschicon-bosch-ic-call-frame:before {
  content: "\e8a3";
}
.boschicon-bosch-ic-fast-backward-frame:before {
  content: "\e8a4";
}
.boschicon-bosch-ic-fast-forward-frame:before {
  content: "\e8a5";
}
.boschicon-bosch-ic-skip-backward-frame:before {
  content: "\e8a6";
}
.boschicon-bosch-ic-skip-fast-backward-frame:before {
  content: "\e8a7";
}
.boschicon-bosch-ic-skip-fast-forward-frame:before {
  content: "\e8a8";
}
.boschicon-bosch-ic-skip-forward-frame:before {
  content: "\e8a9";
}
.boschicon-bosch-ic-subtract-frame:before {
  content: "\e8aa";
}
.boschicon-bosch-ic-info-i-frame:before {
  content: "\e8ab";
}
.boschicon-bosch-ic-stop-frame1:before {
  content: "\e8ac";
}
.boschicon-bosch-ic-arrow-up-frame-error1:before {
  content: "\e8ad";
}
.boschicon-bosch-ic-arrow-up-frame-check1:before {
  content: "\e8ae";
}
.boschicon-bosch-ic-arrow-left-end-frame1:before {
  content: "\e8af";
}
.boschicon-bosch-ic-arrow-right-end-frame1:before {
  content: "\e8b0";
}
.boschicon-bosch-ic-facebook-frame:before {
  content: "\ea87";
}
.boschicon-bosch-ic-googleplus-frame:before {
  content: "\ea88";
}
.boschicon-bosch-ic-instagram-frame:before {
  content: "\ea89";
}
.boschicon-bosch-ic-lineapp-frame:before {
  content: "\ea8a";
}
.boschicon-bosch-ic-linkedin-frame:before {
  content: "\ea8b";
}
.boschicon-bosch-ic-pinterest-frame:before {
  content: "\ea8c";
}
.boschicon-bosch-ic-snapchat-frame:before {
  content: "\ea8d";
}
.boschicon-bosch-ic-tumblr-frame:before {
  content: "\ea8e";
}
.boschicon-bosch-ic-twitter-frame:before {
  content: "\ea8f";
}
.boschicon-bosch-ic-vimeo-frame:before {
  content: "\ea90";
}
.boschicon-bosch-ic-wechat-frame:before {
  content: "\ea91";
}
.boschicon-bosch-ic-weibo-frame:before {
  content: "\ea92";
}
.boschicon-bosch-ic-whatsapp-frame:before {
  content: "\ea93";
}
.boschicon-bosch-ic-xing-frame:before {
  content: "\ea94";
}
.boschicon-bosch-ic-youku-frame:before {
  content: "\ea95";
}
.boschicon-bosch-ic-youtube-frame:before {
  content: "\ea96";
}
.boschicon-bosch-ic-vk-frame:before {
  content: "\ea97";
}
.boschicon-bosch-ic-skype-frame:before {
  content: "\ea98";
}
.boschicon-bosch-ic-facebook:before {
  content: "\ea99";
}
.boschicon-bosch-ic-google-frame:before {
  content: "\ea9a";
}
.boschicon-bosch-ic-google:before {
  content: "\ea9b";
}
.boschicon-bosch-ic-googleplus:before {
  content: "\ea9c";
}
.boschicon-bosch-ic-instagram:before {
  content: "\ea9d";
}
.boschicon-bosch-ic-lineapp:before {
  content: "\ea9e";
}
.boschicon-bosch-ic-linkedin:before {
  content: "\ea9f";
}
.boschicon-bosch-ic-pinterest:before {
  content: "\eaa0";
}
.boschicon-bosch-ic-skype:before {
  content: "\eaa1";
}
.boschicon-bosch-ic-snapchat:before {
  content: "\eaa2";
}
.boschicon-bosch-ic-tumblr:before {
  content: "\eaa3";
}
.boschicon-bosch-ic-twitter:before {
  content: "\eaa4";
}
.boschicon-bosch-ic-vimeo:before {
  content: "\eaa5";
}
.boschicon-bosch-ic-vk:before {
  content: "\eaa6";
}
.boschicon-bosch-ic-wechat:before {
  content: "\eaa7";
}
.boschicon-bosch-ic-weibo:before {
  content: "\eaa8";
}
.boschicon-bosch-ic-whatsapp:before {
  content: "\eaa9";
}
.boschicon-bosch-ic-xing:before {
  content: "\eaaa";
}
.boschicon-bosch-ic-youku:before {
  content: "\eaab";
}
.boschicon-bosch-ic-youtube:before {
  content: "\eaac";
}
.boschicon-bosch-ic-alert-error-filled:before {
  content: "\eb08";
}
.boschicon-bosch-ic-alert-error:before {
  content: "\eb09";
}
.boschicon-bosch-ic-alert-success-filled:before {
  content: "\eb0a";
}
.boschicon-bosch-ic-alert-success:before {
  content: "\eb0b";
}
.boschicon-bosch-ic-alert-warning-filled:before {
  content: "\eb0c";
}
.boschicon-bosch-ic-alert-warning:before {
  content: "\eb0d";
}
.boschicon-bosch-ic-alert-info-filled:before {
  content: "\eb0e";
}
.boschicon-bosch-ic-alert-info:before {
  content: "\eb0f";
}
/* TRANSFORM FROM PLUS ICON TO MINUS ICON */
.MuiAccordionSummary-expandIcon.Mui-expanded .boschicon-bosch-ic-add::before{
  transform: rotate(90deg);
  content: "\e1ba";
}